import React, { Component } from 'react';

import Toggle from './components/toggle';
import Input from './components/input';
import ModifyDisplay from '../SearchResults/components/modifyDisplay';
import {SearchContext} from "../context/searchContext";
import FacetGroup from "../SearchFacets";
import './index.scss';

/*
SearchForm component renders radio buttons section to switch between "Search by keyboard" and "Search by case number".
Then it renders the search input field and on mobile view only (max-width 767px) it also renders the SearchFacets component with all filters.
*/
class SearchForm extends Component {

  // Toggle filters on mobile view and small-with screens with max width of 767px
  handleClick = () => {
    if (document.getElementById('mobile-overlay')) {
      if (document.getElementById("mobile-overlay").style.display === "none") {
        document.getElementById("mobile-overlay").style.display = "inherit";
      }
      else if (document.getElementById("mobile-overlay").style.display === "") {
        document.getElementById("mobile-overlay").style.display = "inherit";
      }
      else {
        document.getElementById("mobile-overlay").style.display = "none";
      }
    }
  };

  render() {
    let context = this.context;
    let sortOptions = context.sort_options;
    let formHeader = '';
    if(context.searchDisplay == 'keyword-toggle') {
      formHeader = 'Search by Keyword'
    } else {
      formHeader = 'Search by Case Number'
    }
    return (
      <div id="search-form" className="row--grid gray--light">
        <div className="SearchForm-left"></div>
        <div className="container-inner SearchForm-main">
          {/* On mobile devices (smaller width) only, render the facets container */}
          <FacetGroup mobile={true}/>
          <div className="search-container" style={{ margin: '30px 0' }}>
            <h3>{ formHeader }</h3>
            {/* Render toggle component to switch between keyword and case searches */}
            <Toggle />
            <div className="form-group">
              {/* Render input components for keyword or case searches */}
              <Input />
            </div>
            {context.results && context.results.length > 0 ?
              <ModifyDisplay
                id="display-sort-options-mobile"
                title='Sort by'
                display='orderby'
                options={sortOptions}
              /> : null}
            {/* Render "Filters" button that toggles filters on mobile view. If there are any active filters, display number of them inside the button in parenthesis */}
            {context.facets !== null && context.results && context.results.length > 0 ?
              <button
                className={'mobile-filters'}
                onClick={e => this.handleClick()}>
                {
                  'Filters' + (
                    (this.context.active_filters && this.context.active_filters.length > 0) ||
                    (this.context.date_filters && this.context.date_filters.length > 0) ?
                      ' (' + (
                        (this.context.active_filters && this.context.date_filters) ? (this.context.active_filters.length + this.context.date_filters.length) : 0
                      ) + ')' :
                      '')}
              </button> : null}
          </div>
        </div>
        <div className="SearchForm-right"/>
      </div>
    );
  }
}

SearchForm.contextType = SearchContext;
export default SearchForm;
