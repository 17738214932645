import React, { Component } from "react";
import { SearchContext } from "../../context/searchContext";
import { agency, program_area } from "../../casetype-tooltip";

/*
SingleFacet
*/
class SingleFacet extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  // When a checkbox is checked/unchecked, this method checks if there currently is an active facet in context state..
  handleChange = e => {
    let facet = this.context;
    let isActive = facet.active_filters.find(
      o => o.value === "'" + e.target.value + "'"
    );
    // ... if doesn't yet exist in state, then it adds it to state with name, 'eq' and value
    if (!isActive) {
      this.context.applySingleFilter(
        this.props.name,
        " eq ",
        "'" + this.props.value + "'"
      );
    } else {
      // ... if it already exists in state, then it invokes a context method to clear that facet
      this.context.clearSingleFilter(this.props.name);
    }
  };

  render() {
    let facet = this.context;
    // find and assign to isActive variable an active filter that have the same value as the one passed on to this component as a prop
    let isActive = facet.active_filters.find(
      o => o.value === "'" + this.props.value + "'"
    );
    let label = "";
    // Check whether the value passed on to this component is NOT 'Courts, and it is either agency, case type or program area'
    if (
      this.props.name === "agency" ||
      this.props.name === "case_type" ||
      this.props.name === "program_area"
    ) {
      let tooltipSpan = "";
      // Build tooltips for each facet group using object values from casetype-tooltip.js file
      if (this.props.name === "agency") {
        tooltipSpan = (
          <span className="tooltiptext">
            {agency[this.props.value] ? agency[this.props.value] : ""}
          </span>
        );
      } else if (this.props.name === "case_type") {
        tooltipSpan = (
          <span className="tooltiptext">
            {this.context.case_type_list[this.props.value] ? this.context.case_type_list[this.props.value] : ""}
          </span>
        );
      } else if (this.props.name === "program_area") {
        tooltipSpan = (
          <span className="tooltiptext">
            {program_area[this.props.value]
              ? program_area[this.props.value]
              : ""}
          </span>
        );
      }

      // Build dynamic label with a span defined above
      label = (
        <label htmlFor={this.props.mobile ? [...this.props.value.replace(/\s/g, '_').split(''), '-mobile'].join('') : this.props.value} className="facetTooltip">
          <span>
            {this.props.value} ({this.props.count})
          </span>
          {tooltipSpan}
        </label>
      );
    } else {
      // If this is any other than agency, case type or program area facet, then don't provide it with a tooltip. Only create a label for it
      label = (
        <label htmlFor={this.props.mobile ? [...this.props.value.replace(/\s/g, '_').split(''), '-mobile'].join('') : this.props.value} className="facetTooltip">
          <span>
            {this.props.value} ({this.props.count})
          </span>
        </label>
      );
    }
    return (
      <li>
        {/* Checkbox will be checked if isActive is not falsy - there is a facet in among active-filters that matches current facet */}
        <input
          type="checkbox"
          value={this.props.value}
          // Replace all spaces with underscores
          id={this.props.mobile ? [...this.props.value.replace(/\s/g, '_').split(''), '-mobile'].join('') : this.props.value}
          name={this.props.name}
          checked={!!isActive}
          onChange={e => this.handleChange(e)}
        />
        {label}
      </li>
    );
  }
}

SingleFacet.contextType = SearchContext;
export default SingleFacet;
