import React, { Component } from 'react';

import { SearchContext } from '../../context/searchContext';

/*
ActiveFilter renders a single none-date filter, and provides ability to clear it using a context method.
*/
class ActiveFilter extends Component {
    constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.context.clearSingleFilter(this.props.id);
  }

  render() {

    return (
      <div
      onClick={this.handleClick}
      >
        <span 
        className="cancel"
        >
          <svg 
          className="fa-times"
          viewBox="0 0 1792 1792"
          xmlns="http://www.w3.org/2000/svg"
          >
            <path 
            fill="#D3393B" 
            d="M1490 1322q0 40-28 68l-136 136q-28 28-68 28t-68-28l-294-294-294 294q-28 28-68 28t-68-28l-136-136q-28-28-28-68t28-68l294-294-294-294q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294 294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68l-294 294 294 294q28 28 28 68z"
            >
            </path>
          </svg>
          {this.props.name}
        </span>
      </div>
    );
  }
}

ActiveFilter.contextType = SearchContext;
export default ActiveFilter;
