import React, { Component } from 'react';
import SearchForm from '../SearchForm';
import SearchResults from '../SearchResults';

import { SearchContext } from '../context/searchContext';

class SearchPage extends Component {

  render() {
    return (
      <div>
        <SearchForm />
        <SearchResults />
      </div>
    );
  }
}

SearchPage.contextType = SearchContext;
export default SearchPage;
