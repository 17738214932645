import React from 'react';

function InfoBox(props) {
	const { isDrupal } = props;
	return (
		<div className="usa-alert usa-alert--info">
			<div className="usa-alert-body">
				<h3 className="usa-alert-heading" dangerouslySetInnerHTML={{ __html: isDrupal.banner_title }} />
				<div className="usa-alert-text">
					<div dangerouslySetInnerHTML={{ __html: isDrupal.banner_content }} />
				</div>
			</div>
		</div>
	);
}
export default InfoBox;
