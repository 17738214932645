import React, { Fragment } from 'react';

// Create new context, destructure Provider and Consumer from it, and pass default property of "radioGroup"
const { Provider, Consumer } = React.createContext('radioGroup');

// Function that takes arguments including all children and returns a context provider with some of those arguments passed on as props to that provider
const RadioGroup = ({ selected, onChange, name, disabled, children }) => (
  <Provider
    value={{
      selected,
      onChange,
      name,
      disabledGroup: disabled
    }}
  >
    {children}
  </Provider>
);

// Function that takes arguments and returns a context consumer with arguments passed as props to that consumer as well as props from wrapping provider value object
const RadioButton = ({ id, value, disabled, children }) => (
  <Consumer>
    {({ selected, onChange, disabledGroup, name }) => (
      <Fragment>
        <input
          type="radio"
          checked={selected === id}
          disabled={disabled || disabledGroup}
          id={id}
          value={value || id}
          name={name}
          onChange={onChange}
        />
        <label htmlFor={id}>{children}</label>
      </Fragment>
    )}
  </Consumer>
);
export { RadioGroup, RadioButton };