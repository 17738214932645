import React, { Component } from "react";
import { SearchContext } from "../context/searchContext";
import FacetGroup from "./components/facetGroup";
import DateFilter from "./components/dateFilter";
import CurrentFilters from "./components/currentFilters";

/*
SearchFacets component creates a facets container with all checked filters displaying on top,
and other filter split between regular ones and date filters. Component also distinguishes bentween desktop and mobile
views and renders content accordingly.
*/
class SearchFacets extends Component {
  // Clear all active filters
  handleClear = () => {
    this.context.clearAllFilters();
  };

  render() {
    let currentFilters = null;

    if (
      this.context.active_filters.length > 0 ||
      this.context.date_filters.length > 0
    ) {
      // If there are currently selected filters, then display them on top of facets container
      currentFilters = <CurrentFilters />;
    }
    // For none-mobile view with either results or checked filters...
    if (!this.props.mobile) {
      return this.context.results.length > 0 ||
        (this.context.active_filters.length > 0 ||
          this.context.date_filters.length > 0) ? (
        <fieldset name="Search Filters">
          <div id="facets">
            <div id="facet-wrapper" className="padded-border">
              {currentFilters}
              <ul className="usa-accordion">
                <h2>
                  Filter By:
                  <a
                    href="/agencies/oalj/apps/keyword-search/public-filter-help"
                    rel="noopener noreferrer"
                    className="help-link"
                    target="_blank"
                    title="Filter help"
                  >
                    <svg
                      className="fa-info-circle help-circle"
                      viewBox="0 0 1792 1792"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill="#0071ba"
                        d="M1152 1376v-160q0-14-9-23t-23-9h-96v-512q0-14-9-23t-23-9h-320q-14 0-23 9t-9 23v160q0 14 9 23t23 9h96v320h-96q-14 0-23 9t-9 23v160q0 14 9 23t23 9h448q14 0 23-9t9-23zm-128-896v-160q0-14-9-23t-23-9h-192q-14 0-23 9t-9 23v160q0 14 9 23t23 9h192q14 0 23-9t9-23zm640 416q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"
                      />
                    </svg>
                    Filter Help
                  </a>
                </h2>
                <hr />
                {/* Render facets */}
                <FacetGroup mobile={false} />
                <DateFilter />
              </ul>
            </div>
          </div>
        </fieldset>
      ) : null;
    } else {
      // For mobile with with results or filters...
      return this.context.results.length > 0 ||
        (this.context.active_filters.length > 0 ||
          this.context.date_filters.length > 0) ? (
        <div id="mobile-overlay" className={"mobileOverlay"}>
          <div className="closeClearBtns">
            <button
              className={"mobileClose"}
              onClick={e =>
                (document.getElementById("mobile-overlay").style.display =
                  "none")
              }
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="arrow-left"
                className="svg-inline--fa fa-arrow-left fa-w-14"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="#0071ba"
                  d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"
                />
              </svg>
              Close
            </button>
            <button
              className={"mobileClear"}
              onClick={e => this.handleClear(e)}
            >
              Clear All
            </button>
          </div>

          <fieldset name="Mobile Search Filters">
            <div className={"mobileFilter"}>
              <div className="padded-border">
                {currentFilters}
                <ul className="usa-accordion">
                  <h2>
                    Filter By
                    {this.context.active_filters.length > 0 ||
                    this.context.date_filters.length > 0
                      ? " (" +
                        (this.context.active_filters.length +
                          this.context.date_filters.length) +
                        ")"
                      : ""}
                  </h2>
                  <hr />
                  <FacetGroup mobile={true} />
                  <DateFilter />
                </ul>
                <a
                  className="mobile-help"
                  href="/agencies/oalj/apps/keyword-search/public-filter-help.html"
                  target="_blank"
                  title="Filter help"
                >
                  Filter Help
                </a>
              </div>
            </div>
          </fieldset>
        </div>
      ) : null;
    }
  }
}

SearchFacets.contextType = SearchContext;
export default SearchFacets;
