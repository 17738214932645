import React, { Component } from "react";
import { SearchContext } from "../../context/searchContext";

/*
Using skip (for pagination) and top (max results per page) values from global context state, ResultCount displays a message
summarizing the number or results shown on the page.
*/
class ResultCount extends Component {

  render() {
    let resultCountContext = this.context;
    let pageMax = Math.ceil((resultCountContext.count / resultCountContext.top));

    let shownTotal = resultCountContext.count == 100000 ?
      resultCountContext.results.length * pageMax :
      resultCountContext.count;

    const showing = resultCountContext.top * pageMax;
    if (resultCountContext.displayQuery != null) {
      resultCountContext.displayQuery = resultCountContext.displayQuery.replace(new RegExp('af69dff86e', 'g'), "§").replace(new RegExp('32ebb1abcc', 'g'), "(").replace(new RegExp('ba5ec51d07', 'g'), ")");
    }
    return (
      <div id="result-counter" className="flex-item-1">
        <span>
          Showing {resultCountContext.skip + 1} through{" "}
          {/* If number of results to skip based on page plus max number of results is more that the total number of results... */}
          {resultCountContext.skip + resultCountContext.top}{" "}
          of {resultCountContext.count} results {resultCountContext.searchDisplay === 'keyword-toggle' ? 'for ' : ''}
          {/* Format the search expression by removing any mentions of ~ following 1 */}
          {resultCountContext.searchDisplay === 'keyword-toggle' ? <b>{'"' + resultCountContext.displayQuery + '"'}</b> : ''}
        </span>

      </div>
    );
  }
}

ResultCount.contextType = SearchContext;
export default ResultCount;
