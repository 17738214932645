import React, { Component } from 'react';
import { SearchContext } from '../../context/searchContext';

/*
ModifyDisplay renders a dropdown based on props passed on this this component.
Also, it uses various properties from global context state, and it invokes a context method for modifying display of results.
*/
class ModifyDisplay extends Component {

  render(){
    let defaultValue = null;
    // Set the defaultValue based on received display prop
    if (this.props.display === 'top') {
      defaultValue = this.context.top;
    }
    else {
      defaultValue = this.context.orderby;
    }

    return(
      // Set a title based on title received as a prop
      <div className={"modifyDisplay " + this.props.title}>
      <p>{this.props.title + ':'}</p>
        {/* Invisible label for 508 accessibility */}
        <label htmlFor={this.props.id} className="visually-hidden">{this.props.title}</label>
        <select
        id={this.props.id}
        onChange={(e => {this.context.modifyDisplay(this.props.display, e.target.value)})}
        defaultValue={defaultValue}
        >
        {/* Loop through all options currently in global context state, and map options element for each one of them */}
        {this.props.options.map((item) => (
          <option key={item.value} value={item.value}>{item.text}</option>
          ))
        }
        </select>
      </div>
      );
  }
}

ModifyDisplay.contextType = SearchContext;
export default ModifyDisplay;