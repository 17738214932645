import React from 'react';
import { RadioButton, RadioGroup } from '../context/radioControls';
import { SearchContext } from '../../context/searchContext';

/*
Toggle component renders RadioGroup and RadioButtons context components to have
radio buttons section switch between "Search by keyboard" and "Search by case number".
Also, it renders a "Search Instructions" link.
*/
export default () => {
    return (
      <SearchContext.Consumer>
      { toggleVal => (
        <div>
          <fieldset className="usa-fieldset-inputs">
          <ul className="usa-unstyled-list list-inline d-inline">
            <RadioGroup
              name="searchDisplays"
              selected={toggleVal.searchDisplay}
              onChange={toggleVal.changeDisplay}
            >
              <li>
              <RadioButton id="keyword-toggle">Search by Keyword</RadioButton>
              </li>
              <li>
              <RadioButton id="case-number-toggle">Search by Case Number</RadioButton>
              </li>
            </RadioGroup>
            </ul>
            {/* Display different instructions based on whether keyword or if case search box is checked */}
            <a href={toggleVal.searchDisplay === 'keyword-toggle' ? "/agencies/oalj/apps/keyword-search/public-advanced-search" : "/agencies/oalj/apps/keyword-search/public-search-instructions"} target="_blank" rel="noopener noreferrer" className="f-right search-instructions-link">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" className="svg-inline--fa fa-info-circle fa-w-16 icon-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="#0071ba" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
              </svg>
              Search Instructions
            </a>
          </fieldset>
        </div>
        )}
      </SearchContext.Consumer>
    );
}
