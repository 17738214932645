import React, { Component } from "react";
import { SearchContext } from "../../context/searchContext";
import Collapsible from "react-collapsible";
import moment from "moment";
import ErrorAlert from "./errorAlert";
import ModifiedDatePicker from "./modifiedDatePicker";

/*
FacetGroup component renders a collapsible accordion with lower and upper dates to set a range. 
The component also handles all the logic and invokes context methods to handle date filtering.
In addition, this component renders DatePicker as well as ErrorAlert component passing on props to them.
*/
class FacetGroup extends Component {
  constructor() {
    super();
    this.state = {
      showError: false,
      wrongDate: "",
      buttonDisabled: false,
      startKey: 0,
      endKey: 1,
    };
    this.submitDateRange = this.submitDateRange.bind(this);
    this.handleUpperChange = this.handleUpperChange.bind(this);
    this.formatDate = this.formatDate.bind(this);
    this.handleLowerChange = this.handleLowerChange.bind(this);
    this.clearDateRange = this.clearDateRange.bind(this);
    this.validateDates = this.validateDates.bind(this);
    this.handleErrorInUi = this.handleErrorInUi.bind(this);
    this.validateIndividualDateInputs = this.validateIndividualDateInputs.bind(this);
    this.clearIndividualDate = this.clearIndividualDate.bind(this);
    this.keyDownClearInput = this.keyDownClearInput.bind(this);
    this.handleBackSpace = this.handleBackSpace.bind(this);
  }
  // Apply formatting to a UTC date string
  formatDate(date) {
    //html date formatting for DOM
    let d = new moment(date);
    return d.format("YYYY-MM-DD");
  }
  // Set changed lower and upper dates into local state
  async handleLowerChange(date) {
    if (Date.parse(date)) {
      await this.context.setLowerDate(date);
    }
    this.validateDates("lower");
  }

  async handleUpperChange(date) {
    if (Date.parse(date)) {
      await this.context.setUpperDate(date);
    }
    this.validateDates("upper");
  }

  // Update state to add/remove UI error handling
  handleErrorInUi(errorExists, type = "") {
    this.setState({
      showError: errorExists,
      buttonDisabled: errorExists,
      wrongDate: type ? type : ""
    });
  }

  // Take a date type 'lower' or 'upper' and validate that date
  validateDates(type) {
    // Convert UTC date into unix milliseconds form start
    const lowerDateInMilliseconds = this.context.local_lower_date
      ? Date.parse(this.context.local_lower_date)
      : null;
    const upperDateInMilliseconds = this.context.local_upper_date
      ? Date.parse(this.context.local_upper_date)
      : null;

    /* LOWER DATE VALIDATION */
    if (type === "lower") {
      // Check if upper date exists and if lower date is more than upper date
      if (
        upperDateInMilliseconds &&
        lowerDateInMilliseconds > upperDateInMilliseconds
      ) {
        // If true, handle UI with error alert etc
        this.handleErrorInUi(true, "lower");
        return false;
      }
      // If not, remove error alert and enable button
      this.handleErrorInUi(false);
      return true;
    } else {
      /* UPPER DATE VALIDATION */
      // Check if lower date exists and if upper date is less than lower date
      if (
        lowerDateInMilliseconds &&
        upperDateInMilliseconds < lowerDateInMilliseconds
      ) {
        // If true, handle UI with error alert etc
        this.handleErrorInUi(true, "upper");
        return false;
      }
      // If not, remove error alert and enable button
      this.handleErrorInUi(false);
      return true;
    }
  }

  // Validate each individual date input
  validateIndividualDateInputs() {
    let valid = true;
    const reactDatePickers = document.querySelectorAll(
      "#facets .react-date-picker"
    );
    [...reactDatePickers].forEach(rdp => {
      // Count number of values within a group
      let numOfVals = 0;
      // Cound number of invalid values
      let numOfInvalidVals = 0;

      const inputGroups = rdp.querySelectorAll(
        ".react-date-picker__inputGroup__input"
      );

      [...inputGroups].forEach(input => {
        if (input.value) {
          numOfVals = numOfVals + 1;
        }

        if (!input.checkValidity()) {
          numOfInvalidVals = numOfInvalidVals + 1;
        }
      });

      const groupHasAnyValue = numOfVals > 0;
      const groupHasAnyInvalidValue = numOfInvalidVals > 0;

      if ((groupHasAnyValue && numOfVals !== 3) || groupHasAnyInvalidValue) {
        valid = false;
      }
    });
    return valid;
  }

  // Clear pre-selected date range filters
  /*=== MUST BE ASYNC+AWAIT OR USE CALLBACK!!! ===*/
  async clearDateRange() {
    // Reset state values
    await this.setState({
      // lower_date: "",
      // upper_date: "",
      showError: false,
      wrongDate: "",
      buttonDisabled: false,
      endKey: this.state.endKey + 2,
      startKey: this.state.startKey + 2
    });
    // Clear context date filters
    await this.context.setLowerDate("");
    await this.context.setUpperDate("");
    await this.context.applyDateFilter([null, null]);
  }

  async clearIndividualDate(date){
    if(date === 'start'){
      await this.setState({
      showError: false,
      wrongDate: "",
      buttonDisabled: false,
      startKey: this.state.startKey + 2
    });
    await this.context.setLowerDate("");
    }else{
      await this.setState({
      showError: false,
      wrongDate: "",
      buttonDisabled: false,
      endKey: this.state.endKey + 2
    });
    await this.context.setUpperDate("");
    }
  }

  keyDownClearInput(e,date){
    if((e.keyCode === 13) || (e.which === 13)){
      this.clearIndividualDate(date);
    }
  }

  handleBackSpace(e,date){
    var key = e.which || e.charCode;
    if((key === 46)||(key === 8)){
      e.preventDefault();
      this.clearIndividualDate(date);
   }
  }

  // Using lower and upper dates in state, build an object that contains operators needed for Azure Search date filtering
  submitDateRange() {
   if (this.validateIndividualDateInputs()) {
      let lowerDate = null;
      let upperDate = null;
      if (this.context.local_lower_date !== "") {
        lowerDate = {
          name: "issued_date",
          comparator: " ge ",
          value: this.formatDate(this.context.local_lower_date) + "T00:00:00Z"
        };
      }
     if (this.context.local_upper_date !== "") {
        upperDate = {
          name: "issued_date",
          comparator: " le ",
          value: this.formatDate(this.context.local_upper_date) + "T23:59:59Z"
        };
      }
      this.context.applyDateFilter([lowerDate, upperDate]);
    } else {
      this.setState({ showError: true, buttonDisabled: true });
    }
  }

  // !(facets[facet_group.name].map(f => f.value).includes("BRB"))
  render() {
    // Function that returns true or false if passed on param matches an active filter value
    const activeFiltersContain = filter =>
      this.context.active_filters.map(activeFilter => activeFilter.value).includes(`'${filter}'`);
    // Boolean used to check if active filters array contains BRB or ARB
    const activeFiltersContainArbOrBrb = activeFiltersContain("BRB") || activeFiltersContain("ARB");

    return (
      <div className={"Accordion"}>
        {/* Render a collapsable accordion with lower and upper dates */}
        <Collapsible
          key={"OALJ Date Range"}
          trigger={"OALJ Date Range"}
          tabIndex={0}
          // If there currently is an active filter of BRB or ARB then disable the Date Range collapsable
          triggerDisabled={activeFiltersContainArbOrBrb}
        >
          {/* Check if showError is true. If it is, render ErrorAlert component passing a message as a prop */}
          <ErrorAlert
            message={
              this.state.wrongDate
                ? [
                    "The date range you have entered will not return results. Please check that the ",
                    <strong key="bold">
                      {this.state.wrongDate === "lower" ? "Start" : "End"}
                    </strong>,
                    " date is ",
                    <strong key={"bold2"}>
                      {this.state.wrongDate === "lower" ? "before" : "after"}
                    </strong>,
                    " the ",
                    <strong key={"bold3"}>
                      {this.state.wrongDate === "lower" ? "End" : "Start"}
                    </strong>,
                    " date."
                  ]
                : 'Invalid date format. Please enter the date in the following format: "mm/dd/yyyy".'
            }
            customClass="dates-error-alert"
            display={this.state.showError ? "block" : "none"}
          />

          <div
            className="usa-accordion-content date-range"
            aria-hidden="false"
            id="a6"
          >        
            <label htmlFor="date-range-lower">Start Date:</label>         
            {/* Render a date picker on click inside any of the date input fields */}
            <ModifiedDatePicker
              // value = {this.state.lower_date}
              value={this.context.local_lower_date}
              onChange={this.handleLowerChange}
              maxDate={new Date()}
              minDate={new Date("1950-01-02")}
              clearIcon={null}
              onKeyDown={(e) =>this.handleBackSpace(e,"start")}
              // showLeadingZeros={true}
              format={"M/d/yyyy"}
              key={this.state.startKey}
              className={
                this.state.wrongDate && this.state.wrongDate === "lower"
                  ? "input-with-error"
                  : ""
              }
              disabled={activeFiltersContainArbOrBrb}
            />
            <div className="date-picker-icon-container">
              <input type="button" title="Clear Start Date"  className="date-input-clear-icon" onKeyDown={(e)=>this.keyDownClearInput(e,"start")} onClick={()=>this.clearIndividualDate("start")}></input>
            </div>
            
            <label className={"upper-date"} htmlFor="date-range-upper">
              End Date:
            </label>
            <ModifiedDatePicker
              value={this.context.local_upper_date}
              onChange={this.handleUpperChange}
              onKeyDown={(e) =>this.handleBackSpace(e,"end")}
              maxDate={new Date()}
              // showLeadingZeros={true}
              minDate={new Date("1950-01-02")}
              clearIcon={null}
              format={"M/d/yyyy"}
              key={this.state.endKey}
              className={
                this.state.wrongDate && this.state.wrongDate === "upper"
                  ? "input-with-error"
                  : ""
              }
              disabled={activeFiltersContainArbOrBrb}
            />
            <div className="date-picker-icon-container">
              <input type="button" title="Clear End Date" className="date-input-clear-icon" onKeyDown={(e)=>this.keyDownClearInput(e,"end")} onClick={()=>this.clearIndividualDate("end")}></input>
            </div>
            <div id="clear-filters-button">
              {/* Submit date filters button that calls submitDateRange method on click */}
              <input
                type="button"
                title="Submit Date Button"
                id="submit-date-filter"
                className="usa-button"
                onClick={this.submitDateRange}
                value="Submit Date(s)"
                disabled={this.state.buttonDisabled || activeFiltersContainArbOrBrb}
              />
              {/* Clear date button */}
              <input  
                type="button"
                title="Clear Date Button"
                id="clear-date-filter"
                className="usa-button"
                onClick={this.clearDateRange}
                value="Clear Date(s)"
                disabled={activeFiltersContainArbOrBrb}
              />  
            </div>
          </div>
        </Collapsible>
      </div>
    );
  }
}

FacetGroup.contextType = SearchContext;
export default FacetGroup;
