import fetch from 'isomorphic-fetch';

require('es6-promise').polyfill();

export default function fetchAPI(value) {
  let date_filters = [];

  // Loop through any date filters that have been checked by user and stored in searchContext state. If there are any, then push them ito data_filters array
  for (let i = 0; i < value.date_filters.length; i++) {
    if (value.date_filters[i] !== null) {
      date_filters.push(value.date_filters[i]);
    }
  }

  // Build an object with all parameters that will be posted to Azure Search in order to get fresh results
  let params = {
      'search': value.query,
      // 'search': 'black lung',
      'count': value.displayCount,
      'highlight': value.highlight,
      'top': value.top,
      'queryType': value.queryType,
      'orderby': value.orderby,
      'skip': value.skip,
      'facets': value.facet_list.map(facet_name => facet_name.name + ",count:100"),
      'filter': value.active_filters.concat(value.case_filters).concat(date_filters)
        .map(filter => filter ? filter.name + filter.comparator + filter.value : '').join(' and ')
  };
  let responseHeaders;
  // POST fetch from Azure Search passing on parameters defined above as a body of the request
  fetch('https://'
      + value.searchService
      + '.search.windows.net/indexes/'
      + value.indexName
      + '/docs/search?api-version='
      + value.apiVersion,
      {
          method: 'POST',
          headers: {
              'api-key': value.queryKey,
              'Content-Type': 'application/json',
              "x-ms-azs-return-searchid": "true",
              "Access-Control-Expose-Headers": "x-ms-azs-searchid"
          },
          body: JSON.stringify(params)
      })
      .then(response => {
        responseHeaders = response.headers;
        return response.json();
      })
      .then(data => {
        // let searchId = responseHeaders.get('x-ms-azs-searchid');
        // window.appInsights.trackEvent("Search", {
        //   SearchServiceName: "oalj",
        //   SearchId: searchId,
        //   IndexName: "oalj-index",
        //   QueryTerms: value.query,
        //   ResultCount: data["@odata.count"]
        // });
        // Once data from Azure Search has been received and parsed from JSON into JavaScript object, pass that data as well as the current context to handleResponse method within searchContext
        value.handleResponse(value, data);
      });
}
