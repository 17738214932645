import React, { Component } from 'react';
import SingleFacet from './singleFacet';
import Collapsible from 'react-collapsible';
import './arrow.css';

import { SearchContext } from '../../context/searchContext';

/*
FacetGroup component loops through all the pre-defined facer groups from context state. Then it checks whether any of
the facets returned by Azure Search have a key with facet group name. If it does, then a collapsible accordion element 
is displayed with un-ordered list consisting of all single facet elements. If there are no matching facets, then
the same container is displayed, but this time it is disabled and greyed out.
*/
class FacetGroup extends Component {
  render() {
    let facet_group = this.context.facet_list;
    let facets = this.context.facets;
    // Check if Azure Search query returned any facets...
    if(facets !== null) {
    return (
      <div>
      {
        // Loop through facet ground in context state...
        facet_group.map((facet_group, i) =>
          // For a facet with a key matching facet group's name...
          facets[facet_group.name].length > 0 ?
          <div className='Accordion' key={ facet_group.name }>
          {/* Render an NPM collapsable component with ul of facet group names */}
            <Collapsible
              key={i}
              trigger={facet_group.title }
              transitionTime={200}
              tabIndex={0}
            >
              <ul
                id={ facet_group.name }
                className="usa-unstyled-list"
              >
                {
                  // Loop through all facets of specific group...
                  facets[facet_group.name].map((facet) =>
                  // for each facer, render SingleFacet component, passing on props to it
                    <SingleFacet
                      key={facet.value}
                      value={facet.value}
                      count={facet.count}
                      name={facet_group.name}
                      mobile={this.props.mobile}
                    />
                  )}
              </ul>
            </Collapsible>
          </div> :
          // If there isn't a single facer with facet group's name as a key, then render the same content but disabled and greyed out
            <div className='Accordion' key={ facet_group.name }>
              <Collapsible
                key={i}
                trigger={facet_group.title }
                tabIndex={0}
                triggerDisabled={true}
              >
                <ul
                  id={ facet_group.name }
                  className="usa-unstyled-list"
                >
                  {
                    facets[facet_group.name].map((facet) =>
                      <SingleFacet
                        key={facet.value}
                        value={facet.value}
                        count={facet.count}
                        name={facet_group.name}
                        mobile={this.props.mobile}
                      />
                    )}
                </ul>
              </Collapsible>
            </div>
        )}
      </div>
    );
  } else {
    return null;
  }
  }
}

FacetGroup.contextType = SearchContext;
export default FacetGroup;
