import React from "react";
import update from "immutability-helper";
import { case_type } from "../casetype-tooltip";

export const SearchContext = React.createContext();

/*
SearchProvider is a global context store with state and methods consumed by other components.
*/
class SearchProvider extends React.Component {
  constructor(props) {
    super(props);

    // Based on toggle, change the display from keyword search to case number search.
    // Also, clear the search query, results and filters arrays and skip to first page.
    let changeDisplay = (this.changeDisplay = () => {
      this.setState(state => {
        const newDisplay =
          state.searchDisplay === "keyword-toggle"
            ? "case-number-toggle"
            : "keyword-toggle";
        return {
          searchDisplay: newDisplay,
          query: null,
          caseSearchErrorAlert: false,
          results: [],
          active_filters: [],
          date_filters: [],
          case_filters: [],
          skip: 0
        };
      });
    });

    // Change the display to default state
    let resetDisplay = (this.resetDisplay = (searchType) => {
      const order = searchType === 'keyword-toggle' ? "search.score() desc" : "issued_date desc";
      this.setState({
          searchDisplay: searchType,
          query: null,
          caseSearchErrorAlert: false,
          results: [],
          active_filters: [],
          date_filters: [],
          case_filters: [],
          skip: 0,
          top: window.screen.width <= 767 ? 10 : 30,
          orderby: order
        });
    });

    let changeCaseSearchErrorAlert = (this.caseSearchErrorAlert = value => {
      this.setState({ caseSearchErrorAlert: value });
    });

    // Skip to a page based on max number of results plus the value received as an argument to this method and set loading to true
    let switchPage = (this.changeDisplay = pageNum => {
      this.setState({ skip: this.state.top * (pageNum - 1), isLoading: true });
    });

    // Handle response data from Azure Search from fetchAPI.js
    let handleResponse = (this.handleResponse = (value, data) => {
      // If there currently is a search value within query parameter in searchContext, then toggle hasUpdated property
      if (this.state.query) this.state.hasUpdated = !this.state.hasUpdated;
      // If there is NO search query then end execution of this handleResponse method
      if (this.state.hasUpdated === false) {
        return false;
      }
      if (data.error) {
        this.setState({
          error: true,
          isLoading: false
        });
      } else {
        // Pass data received as an argument to this method into local state
        this.setState({
          error: false,
          facets: data["@search.facets"],
          count: data["@odata.count"],
          results: data["value"],
          isLoading: false
        });
      }
    });

    // Handle search by keyword with fuzzy search capability
    let keywordSearchSubmit = (this.keywordSearchSubmit = async e => {
      // If nothing was typed into search field, then just clear results array and set the query to null
      if (!e) {
        this.setState({ results: [], query: null });
      } else if (this.state.query !== e) {
        let tempE = e;
        // " ` < > # % { } | \ ^ ~ [ ]? unsafe - escape these with \
        e = e.replace(/\\/g, String.raw`\\`); //

        e = e.replace(/“/g, '"');
        e = e.replace(/”/g, '"');
        // e = e.replace(/\(/g, String.raw`\(`); //not needed
        // e = e.replace(/\)/g, String.raw`\)`); //not needed
        e = e.replace(/\|/g, String.raw`\|\|`); //
        // e = e.replace(/\!/g, String.raw`\!`); //not needed
        e = e.replace(/\{/g, String.raw`\{`); //
        e = e.replace(/\}/g, String.raw`\}`); //
        e = e.replace(/\[/g, String.raw`\[`); //
        e = e.replace(/\]/g, String.raw`\]`); //
        e = e.replace(/\^/g, String.raw`\^`); //
        e = e.replace(/~/g, String.raw`\~`); //
        e = e.replace(/\*/g, String.raw`\*`); // not needed
        e = e.replace(/\?/g, String.raw`\?`); //
        //; / ? : @ = + & reserved - encode these
        e = e.replace(/;/g, encodeURI(';'));
        e = e.replace(/\//g, encodeURI('/'));
        e = e.replace(/\?/g, encodeURI('?'));
        e = e.replace(/:/g, encodeURI(':'));
        e = e.replace(/@/g, encodeURI('@'));
        e = e.replace(/=/g, encodeURI('='));
        e = e.replace(/\+/g, encodeURI('+'));
        e = e.replace(/&/g, encodeURI('&'));

        //broken up pieces of query
        let queryPieces = [];
        let flag = false;
        //if there is a quote in the string we want to handle it differently
        if (e.indexOf('"') !== -1) {
          flag = true;
          //find all the indices of each quote in the string
          var regex = /"/gi, result, indices = [];
          while ( (result = regex.exec(e)) ) {
              indices.push(result.index);
          }
          //
          for (let i = 0; i < indices.length; i++) {
            //if i is 0 we want to check whether the quote is first in the string otherwise
            //terms will get sliced by logic below
            if (i === 0) {
              if (indices[i] !== 0) {
                let fuzzyPart = e.substring(0, indices[i]);
                let fuzzy = fuzzyPart.split(" ");
                for (let f in fuzzy) {
                  if (fuzzy[f].length > 1) {
                    //apply fuzzyness
                    if (fuzzy[f].length > 3) {
                      if (fuzzy[f][0] !== '"' && fuzzy[f][fuzzy[f].length - 1] !== '"') {
                        fuzzy[f] = fuzzy[f].replace(/\(/g, String.raw`\(`); //not needed
                        fuzzy[f] = fuzzy[f].replace(/\)/g, String.raw`\)`); //not needed
                        //replace ! unless it's the first character in the word
                        fuzzy[f] = fuzzy[f][0] + fuzzy[f].substring(1).replace(/!/g, String.raw`\!`);
                        fuzzy[f] = fuzzy[f] + String.raw`~1`;
                      }
                    }
                    else {
                      fuzzy[f] = fuzzy[f].replace(/\(/g, String.raw`\(`); //not needed
                      fuzzy[f] = fuzzy[f].replace(/\)/g, String.raw`\)`); //not needed
                      //replace ! unless it's the first character in the word
                      fuzzy[f] = fuzzy[f][0] + fuzzy[f].substring(1).replace(/!/g, String.raw`\!`);
                    }
                  }
                }
                queryPieces.push(fuzzy);
              }
            }
            //determines that the quote is an opening quote
            if (i % 2 === 0) {
              queryPieces.push(e.substring(indices[i], indices[i+1]))
            }
            //handling for that last quote in the string and apply fuzzyness
            else if (i === indices.length) {
              let fuzzyPart = e.substring(indices[i]);
              let fuzzy = fuzzyPart.split(" ");
              for (let f in fuzzy) {
                if (fuzzy[f].length > 1) {
                  if (fuzzy[f].length > 3) {
                    if (fuzzy[f][0] !== '"' && fuzzy[f][fuzzy[f].length - 1] !== '"') {
                      fuzzy[f] = fuzzy[f].replace(/\(/g, String.raw`\(`); //not needed
                      fuzzy[f] = fuzzy[f].replace(/\)/g, String.raw`\)`); //not needed
                      //replace ! unless it's the first character in the word
                      fuzzy[f] = fuzzy[f][0] + fuzzy[f].substring(1).replace(/!/g, String.raw`\!`);
                      fuzzy[f] = fuzzy[f] + String.raw`~1`;
                    }
                  }
                  else {
                    fuzzy[f] = fuzzy[f].replace(/\(/g, String.raw`\(`); //not needed
                    fuzzy[f] = fuzzy[f].replace(/\)/g, String.raw`\)`); //not needed
                    //replace ! unless it's the first character in the word
                    fuzzy[f] = fuzzy[f][0] + fuzzy[f].substring(1).replace(/!/g, String.raw`\!`);
                  }
                }
              }
              queryPieces.push(fuzzy);
            }
            //otherwise if not opening or last quote we need to fuzzy it as it is between opening
            //and closing quotes
            else {
              let fuzzyPart = e.substring(indices[i], indices[i+1]);
              let fuzzy = fuzzyPart.split(" ");
              for (let f in fuzzy) {
                if (fuzzy[f].length > 1) {
                  if (fuzzy[f].length > 3) {
                    if (fuzzy[f][0] !== '"' && fuzzy[f][fuzzy[f].length - 1] !== '"') {
                      fuzzy[f] = fuzzy[f].replace(/\(/g, String.raw`\(`); //not needed
                      fuzzy[f] = fuzzy[f].replace(/\)/g, String.raw`\)`); //not needed
                      //replace ! unless it's the first character in the word
                      fuzzy[f] = fuzzy[f][0] + fuzzy[f].substring(1).replace(/!/g, String.raw`\!`);
                      fuzzy[f] = fuzzy[f] + String.raw`~1`;
                    }
                  }
                  else {
                    fuzzy[f] = fuzzy[f].replace(/\(/g, String.raw`\(`); //not needed
                    fuzzy[f] = fuzzy[f].replace(/\)/g, String.raw`\)`); //not needed
                    //replace ! unless it's the first character in the word
                    fuzzy[f] = fuzzy[f][0] + fuzzy[f].substring(1).replace(/!/g, String.raw`\!`);
                  }
                }
              }
              queryPieces.push(fuzzy);
            }
          }
          //the fuzzy bits are broken into further arrays and we need to put them back together
          for (let q in queryPieces) {
            if (Array.isArray(queryPieces[q])) {
              queryPieces[q] = queryPieces[q].join(" ");
            }
          }
        }
        //if no quotes at all in search term we fuzzy everything
        else {
          queryPieces = e.split(" ");
          for (let f in queryPieces) {
            if (queryPieces[f].length > 1) {
              if (queryPieces[f].length > 3) {
                if (queryPieces[f][0] !== '"' && queryPieces[f][queryPieces[f].length - 1] !== '"') {
                  queryPieces[f] = queryPieces[f].replace(/\(/g, String.raw`\(`); //not needed
                  queryPieces[f] = queryPieces[f].replace(/\)/g, String.raw`\)`); //not needed
                  //replace ! unless it's the first character in the word
                  queryPieces[f] = queryPieces[f][0] + queryPieces[f].substring(1).replace(/!/g, String.raw`\!`);
                  queryPieces[f] = queryPieces[f] + String.raw`~1`;
                }
              }
              else {
                queryPieces[f] = queryPieces[f].replace(/\(/g, String.raw`\(`); //not needed
                queryPieces[f] = queryPieces[f].replace(/\)/g, String.raw`\)`); //not needed
                //replace ! unless it's the first character in the word
                queryPieces[f] = queryPieces[f][0] + queryPieces[f].substring(1).replace(/!/g, String.raw`\!`);
              }
            }
          }
        }

        await this.clearAllFilters();
        await this.resetDisplay("keyword-toggle");
        await this.setState({
          query: flag ? queryPieces.join("") : queryPieces.join(" "),
          displayQuery: tempE,
          top: window.screen.width < 768 ? 10 : this.state.top,
          isLoading: true,
          skip: 0
        });
      }
    });

    // Handle case search
    let caseSearchSubmit = (this.caseSearchSubmit = async filters => {
      await this.clearAllFilters();
      await this.resetDisplay("case-number-toggle");
      await this.setState({
        case_filters: filters,
        query: "*",
        top: window.screen.width < 768 ? 10 : this.state.top,
        isLoading: true,
        skip: 0
      });
    });

    /* Get case filters from Input component's caseSearchSubmit method and set/update active_filters */
    let applySingleFilter = (this.applySingleFilter = (
      name,
      comparator,
      value
    ) => {
      // Find an index of active filter whose comparator and name match the ones received as arguments to current method
      const index = this.state.active_filters.findIndex(
        filter => filter.comparator === comparator && filter.name === name
      );
      // If an active filter DOES exist...
      if (index > -1) {
        // ... locate and update that active filter of current context state under active_filters property to value passed to this applySingleFilter method
        const newObj = update(this.state, {
          active_filters: {
            [index]: {
              value: { $set: value }
            }
          }
        });
        newObj["skip"] = 0; // Set skip to 0 so that page one loads
        newObj["isLoading"] = true;
        this.setState(newObj);
      } else {
        // If that active filter is not currently present in the context state, then push it to active_filters array in context state
        this.setState({
          active_filters: [
            ...this.state.active_filters,
            { name, comparator, value }
          ],
          skip: 0, // Set skip to 0 so that page one loads
          isLoading: true
        });
      }
    });

    /*  */
    let clearSingleFilter = (this.clearSingleFilter = name => {
      // Find an index of active filter whose comparator and name match the ones received as arguments to current method
      const index = this.state.active_filters.findIndex(
        filter => filter.name === name
      );
      // If an active filter DOES exist...
      if (index > -1) {
        // ... remove that active filter from active_filters array
        const newObj = update(this.state, {
          active_filters: {
            $splice: [[index, 1]]
          }
        });
        newObj["skip"] = 0; // Set skip to 0 so that page one loads
        newObj["isLoading"] = true;
        this.setState(newObj);
      }
    });

    // Clear a date that has been selected at the date filters
    let clearSingleDate = (this.clearSingleDate = (name, comparator) => {
      const index = this.state.date_filters.findIndex(filter =>
        filter !== null
          ? filter.name === "issued_date" && filter.comparator === comparator
          : false
      );
      if (index > -1) {
        const newObj = update(this.state, {
          date_filters: {
            $splice: [[index, 1, null]]
          }
        });
        newObj["skip"] = 0;
        newObj["isLoading"] = true;
        this.setState(newObj);
      }
      if (comparator === " ge ") {
        this.setState({ lower_date: "", local_lower_date: "" });
      }
      if (comparator === " le ") {
        this.setState({ upper_date: "", local_upper_date: "" });
      }
    });

    // Apply a date filter
    let applyDateFilter = (this.applyDateFilter = dates => {
      this.setState({
        date_filters: dates,
        skip: 0,
        isLoading: true,
        lower_date:
          dates[0] !== null
            ? dates[0]["value"].replace("T00:00:00Z", "")
            : null,
        upper_date:
          dates[1] !== null ? dates[1]["value"].replace("T23:59:59Z", "") : null
      });
    });

    let setLowerDate = (this.setLowerDate = (date) => {
      this.setState({
        local_lower_date: date
      });
    });
    let setUpperDate = (this.setUpperDate = (date) => {
      this.setState({
        local_upper_date: date
      });
    });

    // Clear all filters
    let clearAllFilters = (this.clearAllFilters = () => {
      this.setState({ active_filters: [], date_filters: [], isLoading: true, local_lower_date: "", local_upper_date: "" });
    });

    // Change display
    let modifyDisplay = (this.modifyDisplay = (display, value) => {
      if (display === "top") {
        this.setState({ [display]: parseInt(value), isLoading: true });
        this.setState({ skip: this.state.top * (1 - 1), isLoading: true }); // automatically set to page 1
      } else {
        this.setState({ [display]: value, isLoading: true });
      }
    });

    // Apply case filters
    let applyCaseFilter = (this.applyCaseFilter = filters => {
      if (filters.length > 0) {
        caseSearchSubmit(filters);
      } else {
        alert("please apply some filters.");
      }
    });

    let caseNumSortOptions = [
      { value: "issued_date desc", text: "Date (Descending)" },
      { value: "issued_date asc", text: "Date (Ascending)" },
      { value: "parsed_title asc", text: "Title (A - Z)" },
      { value: "parsed_title desc", text: "Title (Z - A)" }
    ];

    let drupalSettings = "";

    if (window.drupalSettings !== undefined) {
      drupalSettings = window.drupalSettings.search_configs;
    }

    let getClickData = (this.getClickData = clicked => {
      window.appInsights.trackEvent("Click", {
        SearchServiceName: "oalj",
        ClickedDocId: clicked
      });
    });

    // If there is a drupalSettings object on global scope, then get some of the properties from it, otherwise with initiate the state with custom props
    if (drupalSettings !== "") {
      this.state = {
        searchDisplay: "keyword-toggle",
        searchService: drupalSettings.service_name,
        indexName: drupalSettings.index_name,
        queryKey: drupalSettings.query_key,
        apiVersion: drupalSettings.api_version,
        query: null,
        displayQuery: null,
        caseSearchErrorAlert: false,
        displayCount: drupalSettings.show_count,
        isLoading: false,
        highlight: drupalSettings.highlight_field,
        top: window.screen.width <= 767 ? 10 : 30,
        queryType: drupalSettings.query_type,
        orderby: "search.score() desc",
        skip: 0,
        count: null,
        results: [],
        error: false,
        active_filters: [],
        date_filters: [],
        case_filters: [],
        lower_date: "",
        upper_date: "",
        local_lower_date: "",
        local_upper_date: "",
        count_options: drupalSettings.count_options,
        sort_options: drupalSettings.sort_options,
        case_num_sort_options: caseNumSortOptions,
        facet_list: drupalSettings.facet_list,
        facets: null,
        case_type_list: drupalSettings.case_type_list,
        banner_content: drupalSettings.banner_content,
        banner_title: drupalSettings.banner_title ,
        hasUpdated: false,
        keywordSearchSubmit: keywordSearchSubmit,
        changeCaseSearchErrorAlert: changeCaseSearchErrorAlert,
        changeDisplay: changeDisplay,
        applySingleFilter: applySingleFilter,
        clearSingleFilter: clearSingleFilter,
        clearSingleDate: clearSingleDate,
        applyDateFilter: applyDateFilter,
        setLowerDate: setLowerDate,
        setUpperDate: setUpperDate,
        handleResponse: handleResponse,
        switchPage: switchPage,
        clearAllFilters: clearAllFilters,
        modifyDisplay: modifyDisplay,
        applyCaseFilter: applyCaseFilter,
        caseSearchSubmit: caseSearchSubmit,
        getClickData: getClickData
      };
    } else {
      this.state = {
        searchDisplay: "keyword-toggle",
        searchService: "oalj",
        indexName: "oalj-index",
        queryKey: "D3FBDE7DB34680CD52982126C409B70B",
        apiVersion: "2017-11-11",
        query: null,
        displayQuery: null,
        caseSearchErrorAlert: false,
        displayCount: true,
        isLoading: false,
        highlight: "content",
        top: window.screen.width <= 767 ? 10 : 30,
        queryType: "full",
        orderby: "search.score() desc",
        skip: 0,
        count: null,
        results: [],
        error: false,
        active_filters: [],
        date_filters: [],
        case_filters: [],
        local_lower_date: "",
        local_upper_date: "",
        lower_date: "",
        upper_date: "",
        count_options: [
          { value: 10, text: 10 },
          { value: 30, text: 30 },
          { value: 50, text: 50 },
          { value: 100, text: 100 }
        ],
        sort_options: [
          { value: "issued_date asc", text: "Date (Ascending)" },
          { value: "issued_date desc", text: "Date (Descending)" },
          { value: "search.score() desc", text: "Relevance" },
          { value: "parsed_title asc", text: "Title (A - Z)" },
          { value: "parsed_title desc", text: "Title (Z - A)" }
        ],
        case_num_sort_options: caseNumSortOptions,
        facet_list: [
          { title: "Agencies and Courts", name: "agency" },
          { title: "Document Category", name: "document_category" },
          { title: "Program Area", name: "program_area" },
          { title: "Case Type", name: "case_type" },
          { title: "ALJ Document Type", name: "document_type" },
          { title: "Format", name: "file_type" }
        ],
        facets: null,
        case_type_list: case_type,
        hasUpdated: false,
        keywordSearchSubmit: keywordSearchSubmit,
        changeCaseSearchErrorAlert: changeCaseSearchErrorAlert,
        changeDisplay: changeDisplay,
        applySingleFilter: applySingleFilter,
        clearSingleFilter: clearSingleFilter,
        clearSingleDate: clearSingleDate,
        applyDateFilter: applyDateFilter,
        setLowerDate: setLowerDate,
        setUpperDate: setUpperDate,
        handleResponse: handleResponse,
        switchPage: switchPage,
        clearAllFilters: clearAllFilters,
        modifyDisplay: modifyDisplay,
        applyCaseFilter: applyCaseFilter,
        getClickData: getClickData
      };
    }
  }

  render() {
    return (
      <SearchContext.Provider value={this.state}>
        {this.props.children}
      </SearchContext.Provider>
    );
  }
}

export default SearchProvider;
