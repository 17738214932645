import React from "react";

const ErrorAlert = ({ message, customClass, display }) => (
  <div
    className={"usa-alert usa-alert--error " + customClass}
    role="alert"
    style={{ display }}
  >
    <div className="usa-alert-body">
      <h3 className="usa-alert-heading">Error</h3>
      <p className="usa-alert-text">
        {message}
      </p>
    </div>
  </div>
);

ErrorAlert.defaultProps = {
    message: 'Something went wrong.',
    customClass: 'default-error-alert',
    display: 'none'
}

export default ErrorAlert;
