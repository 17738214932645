import React, { Component } from "react";
import DatePicker from "react-date-picker";

class ModifiedDatePicker extends Component {
  componentDidMount() {
    // Add dates event listeners
    this.addDatesEventListeners();

    // Assign an invisible label for each input of datepicker
    this.addDatepickerInputLabels();

    // Comply buttons with 508
    this.makeDatepickerButtonsAccessible();

    // Change placeholders to MM/DD/YYYY
    this.setDatesPlaceholders();
  }

  componentWillUnmount() {
    // Prevent memory leaks - remove all event listeners on unmount
     const inputGroups = document.querySelectorAll(
        ".react-date-picker__inputGroup"
      );
      [...inputGroups].forEach(inputGroup => {
        const day = inputGroup.querySelector(
            "input.react-date-picker__inputGroup__input.react-date-picker__inputGroup__day"
          ),
          month = inputGroup.querySelector(
            "input.react-date-picker__inputGroup__input.react-date-picker__inputGroup__month"
          ),
          year = inputGroup.querySelector(
            "input.react-date-picker__inputGroup__input.react-date-picker__inputGroup__year"
          );
  
        // Add event listeners on each date input field and call handleDateInputKeyup on each event
        const inputs = [month, day, year];
        inputs.forEach((input, i) => {
          const nextInput = i <= inputs.length - 2 ? inputs[i + 1] : null;
          input.removeEventListener(
            "keyup",
            this.handleDateInputKeyup.bind(this, input.name, nextInput)
          );
        });
      });
  }

  // Listen for any keyup events on any of the date picker input fields
  addDatesEventListeners = () => {
    const inputGroups = document.querySelectorAll(
      ".react-date-picker__inputGroup"
    );
    [...inputGroups].forEach(inputGroup => {
      const day = inputGroup.querySelector(
          "input.react-date-picker__inputGroup__input.react-date-picker__inputGroup__day"
        ),
        month = inputGroup.querySelector(
          "input.react-date-picker__inputGroup__input.react-date-picker__inputGroup__month"
        ),
        year = inputGroup.querySelector(
          "input.react-date-picker__inputGroup__input.react-date-picker__inputGroup__year"
        );

      // Add event listeners on each date input field and call handleDateInputKeyup on each event
      const inputs = [month, day, year];
      inputs.forEach((input, i) => {
        const nextInput = i <= inputs.length - 2 ? inputs[i + 1] : null;
        input.addEventListener(
          "keyup",
          this.handleDateInputKeyup.bind(this, input.name, nextInput)
        );
      });
    });
  };

  // Set a valid number of digits for each input and focus of the next input once that number is reached
  handleDateInputKeyup = (inputName, nextInput, e) => {
    const numOfDigits = inputName === "day" ? 2 : inputName === "month" ? 2 : 4;
    if (nextInput && e.target.value.length >= numOfDigits) {
      nextInput.focus();
    }
  };

  // Add invisible labels for all inputs within date picker for 508 compliance
  addDatepickerInputLabels = () => {
    // Select all input groups within facets container
    const datepickerInputGroups = document.querySelectorAll(
      ".react-date-picker__inputGroup"
    );
    // Convert node list into an array and iterate through it
    [...datepickerInputGroups].forEach((group, g) => {
      // Select all inputs within a group
      const allInputs = group.querySelectorAll("input");
      // Convert node list into an array and iterate through it
      [...allInputs].forEach((input, i) => {
        const dateFieldLabels = [
          ['Start ','End '],
          ['Date','Month','Day','Year']
        ];
        input.setAttribute('aria-label', dateFieldLabels[0][g % 2] + dateFieldLabels[1][i]);
      });
    });
  };

  // Add some text for date picker buttons to comply with 508
  makeDatepickerButtonsAccessible = () => {
    const datePicketButtons = document.querySelectorAll(
      ".react-date-picker__calendar-button.react-date-picker__button"
    );
    // Loop through all buttons
    [...datePicketButtons].forEach(button => {
      // Get child nodes of each button
      const btnChildren = button.childNodes;
      let contains = false;
      // Loop through child nodes of each button and check if
      // any of them already have a span
      [...btnChildren].forEach(btnChild => {
        if (btnChild.nodeName === "SPAN") contains = true;
      });
      // If none of button's children have a span node, then go ahead and add invisible span to it
      if (!contains) {
        button.insertAdjacentHTML(
          "beforeend",
          `<span class="visually-hidden">Date Picker Button</span>`
        );
      }
    });
  };

  // Change default "--" placeholders for dates inside date picker to MM/DD/YYYY
  setDatesPlaceholders = () => {
    const { days, months, years } = this.datesDOMValues();

    // Loop through dateInputs
    [months, days, years].forEach((dateInput, i) => {
      // Loop through each input group
      [...dateInput].forEach(input => {
        let placeholder = "";

        input.style.width = i === 0 ? "29px" : i === 1 ? "19px" : "50px";
        // For mobile view, unset the min-width prop for container element
        // Check for NONE IE browsers
        if (
          window.Element.prototype.closest &&
          input.closest("#mobile-overlay")
        ) {
          input.parentElement.style.minWidth = "unset";
        } else {
          // For IE support
          input.parentElement.style.width = "200px";
          // Set width for each input and parent element
        }

        // Assign placeholder based of element index
        switch (i) {
          case 0:
            placeholder = "mm";
            input.style.paddingLeft = '4px';
            break;
          case 1:
            placeholder = "dd";
            break;
          case 2:
            placeholder = "yyyy";
            break;
          default:
            placeholder = "--";
            break;
        }
        input.placeholder = placeholder;
      });
    });
  };

  // Get DOM values of date fields from date picker  const { days, months, years } = this.datesDOMValues()
  datesDOMValues = (container = "") => {
    const days = document.querySelectorAll(
      `${
        container ? `#${container} ` : ""
      }input.react-date-picker__inputGroup__input.react-date-picker__inputGroup__day`
    );
    const months = document.querySelectorAll(
      `${
        container ? `#${container} ` : ""
      }input.react-date-picker__inputGroup__input.react-date-picker__inputGroup__month`
    );
    const years = document.querySelectorAll(
      `${
        container ? `#${container} ` : ""
      }input.react-date-picker__inputGroup__input.react-date-picker__inputGroup__year`
    );
    return { days, months, years };
  };

  render() {
    return (
      <DatePicker {...this.props} />
    );
  }
}

export default ModifiedDatePicker;
