import React, { Component } from "react";
import ResultCount from "./components/resultCount";
import SingleResult from "./components/singleResult";
import ModifyDisplay from "./components/modifyDisplay";
import { SearchContext } from "../context/searchContext";
import FacetGroup from "../SearchFacets";
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/core";
import "./components/resultsPage.css";
import Pager from "./components/pager";
import "./index.scss";
import InfoBox from "./components/InfoBox";
const firstLoad = css`
  float: right;
  margin-right: 45%;
  margin-top: 15px;
`;
const loader = css`
  margin-top: 40px;
  margin-left: 50%;
`;
/*
SearchResults component displays facets container, count of results, dropdown for sorting or specifying how many results to display
per page; all results, and a pager. When results are still being fetched from server, a loader will appear. Also, if there
are no results found or if nothing has yet been typed then this component displays an appropriate message.
*/
class SearchResults extends Component {
  render() {
    let results = this.context;
    let countOptions = results.count_options;
    let sortOptions = results.searchDisplay === 'keyword-toggle' ? results.sort_options : results.case_num_sort_options;
    const error = results.error;
    const drupalSite = this.context.banner_content ? this.context : false;
    if (error) {
      return (
        <div className="row--grid background--gray SearchResults">
          <div className="SearchResults-left" />
          <div className="container-inner flex-container SearchResults-main">
            <div className="all-results">
              <h3>{`Sorry, something went wrong with your search query.`}</h3>
              <h4>Please refresh the page and try again.</h4>
              <h4>
                If you are trying to use the advanced search features, see the
                {' '}
                <a href={results.searchDisplay === 'keyword-toggle' ? "https://www.oalj.dol.gov/public-advanced-search.html" : "https://www.oalj.dol.gov/public-search-instructions.html"} target="_blank" rel="noopener noreferrer" className="hide-mobile">
                  Search Instructions
                </a>
                {' '}
                page for more information.
              </h4>
            </div>
          </div>
          <div className="SearchResults-right" />
        </div>
      );
    } else if (results.results.length > 0) {
      return (
        <div className="row--grid background--gray SearchResults">
          <div className="SearchResults-left" />
          <div className="container-inner flex-container SearchResults-main">
            {/* Render facets container */}
            <FacetGroup mobile={false} />
            <div className="all-results">
              {/* Display results count information */}
              <ResultCount
                firstShownResult={parseFloat(results.skip + 1)}
                lastShownResult={
                  parseFloat(results.skip) + parseFloat(results.top)
                }
              />
              <div id="modify-display-results-container">
                <ModifyDisplay
                  id="display-sort-options"
                  title="Sort by"
                  display="orderby"
                  options={sortOptions}
                />
                {/* Spefify results per page dropdown */}
                <ModifyDisplay
                  id="display-count-options"
                  title="Display"
                  display="top"
                  options={countOptions}
                />
              </div>
              {/* Sorting options */}
              <div>
                {/* List of all results */}
                {!results.isLoading ? (
                  results.results.map(result => (
                    // A single result
                    <SingleResult
                      key={result.id}
                      title={result.meta_title}
                      path={result.file_path}
                      highlight={result["@search.highlights"]}
                      fiscalYear={result.fiscal_year}
                      caseType={result.case_type}
                      programArea={result.program_area}
                      documentType={result.document_type}
                    />
                  ))
                ) : (
                  // Loader
                  <ClipLoader
                    css={loader}
                    sizeUnit={"px"}
                    size={100}
                    color={"#0071bc"}
                  />
                )}
                {!results.isLoading ? <Pager /> : null}
              </div>
            </div>
          </div>
          <div className="SearchResults-right" />
        </div>
      );
    } else if (results.isLoading) {
      return (
        <div className="row--grid background--gray SearchResults">
          <div className="SearchResults-left" />
          <div className="container-inner SearchResults-main">
            <ClipLoader
              css={firstLoad}
              sizeUnit={"px"}
              size={100}
              color={"#0071bc"}
            />
          </div>
          <div className="SearchResults-right" />
        </div>
      );
      // If something has been typed into search field but no results have been returned
    } else if ((results.query !== null) && (results.searchDisplay === "keyword-toggle")) {
      return (
        <div className="row--grid background--gray SearchResults">
          <div className="SearchResults-left" />
          <div className="container-inner flex-container SearchResults-main">
            <FacetGroup />
            <div className="all-results" tabIndex={0}>
              <h3 tabIndex={0}>{`Your search did not return any results. Please try the search suggestions below.`}</h3>
              <h4 tabIndex={0}>Tips for searching</h4>
              <ul>
                <li tabIndex={0}>Check the spelling of your search</li>
                <li tabIndex={0}>Try a different search</li>
                <li tabIndex={0}>Try using more general words in your search</li>
              </ul>
            </div>
          </div>
          <div className="SearchResults-right" />
        </div>
      );
    } else if ((results.query !== null) && (results.searchDisplay === "case-number-toggle")) {
      return (
        <div className="row--grid background--gray SearchResults">
          <div className="SearchResults-left" />
          <div className="container-inner flex-container SearchResults-main">
            <FacetGroup />
            <div className="all-results" tabIndex={0}>
              <h3 className="case-number-no-results-text" tabIndex={0}>{`Your search did not return any results.`}</h3>
            </div>
          </div>
          <div className="SearchResults-right" />
        </div>
      );
      // If nothing yet has been typed into search field and submitted
    } else {
      return (
        <div className="row--grid background--gray SearchResults">
          <div className="SearchResults-left" />
          <div className="container-inner SearchResults-main">
            {drupalSite ? <InfoBox isDrupal={drupalSite}/> : null}
          </div>
          <div className="SearchResults-right" />
        </div>
      );
    }
  }
}
SearchResults.contextType = SearchContext;
export default SearchResults;
