import React, { Component } from 'react';
import ActiveDateFilter from './activeDateFilter';
import moment from 'moment';

import { SearchContext } from '../../context/searchContext';

/*
CurrentActiveDateFilters loops through any date filters that currently are checked and 
are in context state, then for each date filter it renders ActiveDateFilter passing on 
props to that component.
*/
class CurrentActiveDateFilters extends Component {

  render() {
    return (
      <div>
        {
          this.context.date_filters && this.context.date_filters.map((filter) => {
            if (filter == null) return null;
            let dateString;   
            //filter.comparator will be " ge " for start date and " le " for end date
            if (filter.comparator.replace(/\s/g, '') === 'ge') { // add a date if it is the start date
              dateString = moment(filter.value.replace(/'/g, "")).add('days', 1).format('YYYY-MM-DD')
            } else {
              dateString = moment(filter.value.replace(/'/g, "")).format('YYYY-MM-DD')
            }
            return (
               <ActiveDateFilter
                id={filter.value}
                key={filter.value}
                name={dateString}
                bound={filter.comparator} />
            )
          })
        }
      </div>
    );
  }
}

CurrentActiveDateFilters.contextType = SearchContext;
export default CurrentActiveDateFilters;
