import React, { Component } from 'react';
import ActiveFilter from './activeFilter';

import { SearchContext } from '../../context/searchContext';

/*
CurrentActiveFilters component loops through all checked none-date filters and renders 
ActiveFilter component for each of them passing on props to the component
*/
class CurrentActiveFilters extends Component {

  render() {
    return (
      <div>
        {
          this.context.active_filters.map((filter) => 
            <ActiveFilter
            id={filter.name}
            key={filter.name}
            name={filter.value.replace(/'/g, "")}
            />
          )
        }
      </div>
    );
  }
}

CurrentActiveFilters.contextType = SearchContext;
export default CurrentActiveFilters;
