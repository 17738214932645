import React, { Component } from 'react';
// import { SearchContext } from "../../context/searchContext";


/*
SingleResult receives props for each result and displays all of its formatted text, 
link to open the website as well as some additional information about the result.
*/
class SingleResult extends Component {
  render() {
    let highlightContent = '';
    if (this.props.highlight !== undefined) {
      highlightContent = this.props.highlight.content;
      // replace 'af69dff86e' with '§', '32ebb1abcc' with '(', and 'ba5ec51d07' with ')'
      highlightContent = highlightContent.toString().replace(new RegExp('af69dff86e', 'g'), "§").replace(new RegExp('32ebb1abcc', 'g'), "(").replace(new RegExp('ba5ec51d07', 'g'), ")").replace(new RegExp('\ufffd', 'g'), "");
    }
    return (
      <div className="single-result">
        <h4>
          {/* Open a page based on URL passed as prop in new tab */}
          <a target="_blank" href={this.props.path} rel="noopener noreferrer">{this.props.title}</a>
        </h4>
        {/* Parse HTML received as props into span below. This is done  */}
        <span dangerouslySetInnerHTML={{ __html: highlightContent }} />
        {/* Display props as css styled pills */}
        <div className="pills">
          <span className="meta-pill_year">{this.props.fiscalYear}</span>
          <span className="meta-pill">{this.props.programArea}</span>
          <span className="meta-pill">{this.props.caseType}</span>
          <span className="meta-pill">{this.props.documentType}</span>
        </div>
      </div>
    );
  }
}

// SingleResult.contextType = SearchContext;

export default SingleResult;