import React, { Component } from "react";
import { SearchContext } from "../../context/searchContext";
import "./input.css";

import ErrorAlert from "../../SearchFacets/components/errorAlert";

/*
Renders the search input field.
*/

class Input extends Component {
  constructor(props) {
    super(props);
    this.caseYear = React.createRef();
    this.caseType = React.createRef();
    this.caseNumber = React.createRef();

    // values of "Search by case" fields
    this.state = {
      case_num_fiscal_year: "",
      case_num_case_type: "",
      case_num_case_num: "",
      errorFieldIds: [],
      errorAlert: {
        display: "none",
        message: []
      },
      yearListenerAdded: false,
      numberListenerAdded: false,
      keyupYearVal: "",
      keyupNumberVal: ""
    };

    // Bind local object scope to the following methods
    this.caseSearchSubmit = this.caseSearchSubmit.bind(this);
    this.handleErrorAlert = this.handleErrorAlert.bind(this);
    this.displayErrorAlert = this.displayErrorAlert.bind(this);
    this.removeErrorAlert = this.removeErrorAlert.bind(this);
    this.recordErrorMessage = this.recordErrorMessage.bind(this);
    this.handleClearSearch = this.handleClearSearch.bind(this);
    this.handleCasesKeyup = this.handleCasesKeyup.bind(this);
    this.handleEnter = this.handleEnter.bind(this);
  }

  componentDidMount() {
    const caseYear = document.querySelector("input#case-year");
    const caseNumber = document.querySelector("input#case-number");
    // Add event listeners on load for case year and case number fields
    if (caseYear && !this.state.yearListenerAdded) {
      caseYear.addEventListener(
        "keyup",
        this.handleCasesKeyup.bind(this, "case-year")
      );
      this.setState({ yearListenerAdded: true });
    }

    if (caseNumber && !this.state.numberListenerAdded) {
      caseNumber.addEventListener(
        "keyup",
        this.handleCasesKeyup.bind(this, "case-number")
      );
      this.setState({ numberListenerAdded: true });
    }
  }

  componentDidUpdate() {
    const caseYear = document.querySelector("input#case-year");
    const caseNumber = document.querySelector("input#case-number");
    // Add event listeners on update for case year and case number fields
    if (caseYear && !this.state.yearListenerAdded) {
      caseYear.addEventListener(
        "keyup",
        this.handleCasesKeyup.bind(this, "case-year")
      );
      this.setState({ yearListenerAdded: true });
    }

    if (caseNumber && !this.state.numberListenerAdded) {
      caseNumber.addEventListener(
        "keyup",
        this.handleCasesKeyup.bind(this, "case-number")
      );
      this.setState({ numberListenerAdded: true });
    }
    this.caseInputInterval = setInterval(() => { }, 10);
  }

  componentWillUnmount() {
    // Clear all event listeners
    document
      .querySelector("input#case-year")
      .removeEventListener(
        "change",
        this.handleCasesKeyup.bind(this, "case-year")
      );
    document
      .querySelector("input#case-number")
      .removeEventListener(
        "change",
        this.handleCasesKeyup.bind(this, "case-number")
      );
    // Reset state props
    this.setState({
      yearListenerAdded: false,
      numberListenerAdded: false,
      keyupYearVal: "",
      keyupNumberVal: ""
    });
  }

  // On key up for case year and case number, check if any of the entries are none digits, and delete them from input
  async handleCasesKeyup(type, e) {
    const keyUpType = type === "case-year" ? "keyupYearVal" : "keyupNumberVal";
    const caseType =
      type === "case-year" ? "case_num_fiscal_year" : "case_num_case_num";

    /*
    This check is specific to Firefox and Safari where if an input does not match input type, then the value is set to an empty string.
    In order to avoid that, check if there is any value at all first. Also, inorder to prevent the first digit from not being
    deleted, check for that as well
    */
    if (e.target.value || this.state[keyUpType].length === 1) {
      this.setState({ [keyUpType]: e.target.value });
    }

    // Replace anything other than digits from input value and add it to DOM as well as state
    if (!/^\d+$/.test(e.target.value)) {
      const newValue = this.state[keyUpType].replace(/[^\d]/g, "");
      document.querySelector(`input#${type}`).value = newValue;
      this.setState({ [caseType]: newValue });
    }
  }

  // Check for errors and add/remove "error" class accordingly
  async checkError(e) {
    e.persist();
    // If there is something typed into the field...
    if (e.target.value !== "") {
      this.handleErrorAlert(e);

      // ...and if the value typed into a field is less that or more that what is expected...
      if (
        e.target.value < parseInt(e.target.min) ||
        e.target.value > parseInt(e.target.max)
      ) {
        // ...and if an element currently does not have the error class...
        if (e.target.className.indexOf(" error") === -1) {
          // ...go ahead and give that element an error class
          e.target.className += " error";
        }
      }
      // .. if value of target field is in required range then...
      else {
        // ...remove error class from that element.
        e.target.className = e.target.className.replace(" error", "");
      }
    }
    // If there is nothing typed into the field...
    else {
      await this.setState(oldState => ({
        errorFieldIds: oldState.errorFieldIds.filter(
          errId => errId !== e.target.id
        )
      }));
      if (this.state.errorFieldIds.length === 0) this.removeErrorAlert();
      // If element does already have an "error" class...
      if (e.target.className.indexOf(" error") !== -1) {
        // ... remove that "error class"
        e.target.className = e.target.className.replace(" error", "");
      }
    }
  }

  // Run this function on every change of case search input fields
  async handleErrorAlert(e) {
    const id = e.target.id;
    // Check if the input is valid based on min/max in the DOM
    const valid = e.target.checkValidity();
    if (
      (id === "case-year" && e.target.value.length > 4) ||
      (id === "case-number" && e.target.value.length > 5) ||
      !valid ||
      !/^\d+$/.test(e.target.value)
    ) {
      // If state currently does not have that id in errorFieldIds array then add it to that array
      await this.recordErrorMessage(id);
      this.displayErrorAlert();
    } else {
      // If no conditions match, remove that error id from errorFieldIds in state
      await this.setState(oldState => ({
        errorFieldIds: oldState.errorFieldIds.filter(el => el !== id)
      }));
      // Only if the errorFieldIds array is empty, then remove error alert
      if (this.state.errorFieldIds.length === 0) {
        this.removeErrorAlert();
        // Otherwise - recompute the error alert message
      } else {
        this.displayErrorAlert();
      }
    }
  }

  // Add case search error messages into an array to access them later when displaying an error alert message
  recordErrorMessage(id) {
    this.setState(oldState => ({
      errorFieldIds: !oldState.errorFieldIds.includes(id)
        ? [...oldState.errorFieldIds, id]
        : [...oldState.errorFieldIds]
    }));
  }

  // Display error alert based on most up to date case search errors
  async displayErrorAlert() {
    // const message = ["Please apply valid filters. ", <br key="br-1" />];
    // if (this.state.errorFieldIds.includes("case-year"))
    //   message.push(
    //     "Invalid ",
    //     <strong key="case-year">Case Year</strong>,
    //     ".",
    //     <br key="br-2" />
    //   );
    // if (this.state.errorFieldIds.includes("case-number"))
    //   message.push(
    //     "Invalid ",
    //     <strong key="case-number">Case Number</strong>,
    //     ".",
    //     <br key="br-3" />
    //   );
    await this.context.changeCaseSearchErrorAlert(true);
    await this.setState(oldState => ({
      errorAlert: {
        ...oldState.errorAlert,
        display: "block",
        message: "Please apply valid filters."
      }
    }));
  }

  // Remove error alert
  async removeErrorAlert() {
    await this.context.changeCaseSearchErrorAlert(false);
    await this.setState(oldState => ({
      errorAlert: {
        ...oldState.errorAlert,
        display: "none",
        message: ""
      }
    }));
  }

  // Get values from "Search by case" fields, validate them, add additional Azure Search query strings, and set them into local state
  caseSearchSubmit() {
    let caseFilters = [];
    if (
      this.state.case_num_fiscal_year !== "" &&
      this.caseYear.current.value !== "" &&
      /^\d+$/.test(this.state.case_num_fiscal_year) &&
      this.state.case_num_fiscal_year.length <= 4 &&
      this.state.case_num_fiscal_year > 0 &&
      this.state.case_num_fiscal_year <= new Date().getFullYear() + 1
    ) {
      if(this.state.case_num_fiscal_year.length < 4){
        let fiscalYearSubstrMin = this.state.case_num_fiscal_year;
        let fiscalYearSubstrMax = this.state.case_num_fiscal_year;
        for (let i = 0; i < 4 - this.state.case_num_fiscal_year.length; i++) {
          fiscalYearSubstrMin = fiscalYearSubstrMin + "0";
          fiscalYearSubstrMax = fiscalYearSubstrMax + "9";
        }
        caseFilters.push({
          name: "case_num_fiscal_year",
          comparator: " ge ",
          value: fiscalYearSubstrMin
        });
        caseFilters.push({
          name: "case_num_fiscal_year",
          comparator: " le ",
          value: fiscalYearSubstrMax
        });
      }else{
        caseFilters.push({
          name: "case_num_fiscal_year",
          comparator: " eq ",
          value: this.state.case_num_fiscal_year
        });
      }
    }
    if (this.state.case_num_case_type !== "" && this.caseType.current.value !== "") {
      caseFilters.push({
        name: "case_num_case_type",
        comparator: " eq ",
        value: "'" + this.state.case_num_case_type + "'"
      });
    }
    if (
      this.state.case_num_case_num !== "" &&
      this.caseNumber.current.value !== "" &&
      /^\d+$/.test(this.state.case_num_case_num) &&
      this.state.case_num_case_num > 0 &&
      this.state.case_num_case_num.length <= 5 &&
      this.state.case_num_case_num <= 99999
    ) {
      let formattedNum = 0;
      if (this.state.case_num_case_num.length === 1) {
        formattedNum = "0000" + this.state.case_num_case_num;
      } else if (this.state.case_num_case_num.length === 2) {
        formattedNum = "000" + this.state.case_num_case_num;
      } else if (this.state.case_num_case_num.length === 3) {
        formattedNum = "00" + this.state.case_num_case_num;
      } else if (this.state.case_num_case_num.length === 4) {
        formattedNum = "0" + this.state.case_num_case_num;
      } else {
        formattedNum = this.state.case_num_case_num;
      }
      caseFilters.push({
        name: "case_num_case_num",
        comparator: " eq ",
        value: "'" + formattedNum + "'"
      });
    }
    // If there are filters checked, invoke applyCaseFilter method from context and pass on the caseFilters array to it
    if (caseFilters.length > 0) {
      this.context.applyCaseFilter(caseFilters);
    } else {
      this.displayErrorAlert();
    }
  }

  // Clear all the case search fields
  handleClearSearch() {
    // Reset case fields to empty strings
    this.setState({
      case_num_fiscal_year: "",
      case_num_case_type: "",
      case_num_case_num: "",
      errorFieldIds: []
    });
    this.removeErrorAlert();

    // Reset values on input fields to empty strings
    [this.caseYear.current, this.caseType.current, this.caseNumber.current].forEach(input => {
      input.value = "";
      if (input.className.indexOf(" error") !== -1) {
        // ... remove that "error class"
        input.className = input.className.replace(" error", "");
      }
    });
  }

  async handleEnter(event) {
    if ((event.keyCode === 13) || (event.which === 13)) {
      if(this.state.errorFieldIds.length === 0){
        if(event.target.id === "case-year"){
          await this.caseYear.current.blur();
        }else{
          await this.caseNumber.current.blur();
        }      
        await this.caseSearchSubmit();
      }
    }
  }
  render() {
    let inputContext = this.context;

    return (
      // Handle keyword search
      inputContext.searchDisplay === "keyword-toggle" ? (
        <div id="searchByKeyword" className="flex-container">
          {/* Search field which on key up will check if a pressed key was Enter(Win) or Return(Mac/Lnx) and invoke keywordSearchSubmit method within context */}
          <label htmlFor="keyword-search" className="visually-hidden">
            Search by Keyword
          </label>
          <input
            type="text"
            className="full-width margin-tb-0 margin-r-30 padding-l-40"
            id="keyword-search"
            placeholder="Search Term"
            onKeyUp={event => {
              if (event.key === "Enter") {
                // replace '§' for 'af69dff86e', ( for '32ebb1abcc', ) for 'ba5ec51d07'
                let inputText = event.target.value.replace(new RegExp('§', 'g'), "af69dff86e").replace(new RegExp('[(]', 'g'), "32ebb1abcc").replace(new RegExp('[)]', 'g'), "ba5ec51d07");
                inputContext.keywordSearchSubmit(inputText);
              }
            }}
          />
          <svg
            className="fa-search icon-left"
            viewBox="0 0 1792 1792"
            xmlns="http://www.w3.org/2000/svg"
            title=""
          >
            <path
              d="M1216 832q0-185-131.5-316.5t-316.5-131.5-316.5 131.5-131.5 316.5 131.5 316.5 316.5 131.5 316.5-131.5 131.5-316.5zm512 832q0 52-38 90t-90 38q-54 0-90-38l-343-342q-179 124-399 124-143 0-273.5-55.5t-225-150-150-225-55.5-273.5 55.5-273.5 150-225 225-150 273.5-55.5 273.5 55.5 225 150 150 225 55.5 273.5q0 220-124 399l343 343q37 37 37 90z"
              fill="#0071ba"
            />
          </svg>
          {/* Button that will do the same as key up above but on click -  invoke keywordSearchSubmit method within context */}
          <input
            type="button"
            title="Search"
            value="Search"
            id="keyword-search-submit"
            className="btn-lg btn-primary search-button usa-button usa-search-big"
            onClick={e =>
              inputContext.keywordSearchSubmit(
               document.getElementById("keyword-search").value.replace(new RegExp('§', 'g'), "af69dff86e").replace(new RegExp("[(]", 'g'), "32ebb1abcc").replace(new RegExp('[)]', 'g'), "ba5ec51d07")
              )}
          />
        </div>
      ) : (
          // Handle case search
          <div
            id="collapseOne"
            className="collapse multi-collapse show"
            role="tabpanel"
            aria-labelledby="headingOne"
          >
            <div className="visually-hidden" id="headingOne">
              Search by Case Number
          </div>
            <div className="card-block">
              <ErrorAlert
                // display={this.state.errorAlert.display}
                display={inputContext.caseSearchErrorAlert ? "block" : "none"}
                message={this.state.errorAlert.message}
                customClass="case-search-error-alert"
              />
              {/* Form with controlled inputs that will update state on blur with each one of case search input field values */}
              <form>
                <div id="case-number-search" className="flex-container">
                  <div className="groupLabel">
                    <label htmlFor="case-year">Fiscal Year</label>
                    <input
                      ref={this.caseYear}
                      className="form-control form-Small"
                      type="number"
                      placeholder="YYYY"
                      onKeyDown={(e)=> this.handleEnter(e)}
                      id="case-year"
                      min={1}
                      max={new Date().getFullYear() + 1}
                      onBlur={e => {
                        this.setState({ case_num_fiscal_year: e.target.value });
                      }}
                      onChange={e => this.checkError(e)}
                    />
                  </div>
                  <div className="groupLabel margin-lr-15">
                    <label htmlFor="case-type">Case Type
                    <a href="/agencies/oalj/apps/keyword-search/public-filter-help" target="_blank" rel="noopener noreferrer" className="f-right hide-mobile">
                        <span className="visually-hidden">Information about Case Type</span>
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" className="svg-inline--fa fa-info-circle fa-w-16 icon-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path fill="#0071bc" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
                        </svg>
                      </a>
                    </label>
                    <select
                      ref={this.caseType}
                      id="case-type"
                      name="case-type"
                      onChange={e =>
                        this.setState({ case_num_case_type: e.target.value })
                      }
                      defaultValue={""}
                    >
                      <option value="">Choose a Case Type</option>
                      {Object.keys(this.context.case_type_list).map((case_type, i) =>{
                        return (
                          <option key={i} value={case_type}>{case_type}</option>)
                      })}
                    </select>
                  </div>
                  <div className="groupLabel">
                    <label htmlFor="case-number">Case Number</label>
                    <input
                      ref={this.caseNumber}
                      className="form-control form-Small"
                      type="number"
                      min={1}
                      max={99999}
                      placeholder="NNNNN"
                      id="case-number"
                      onKeyDown={(e)=> this.handleEnter(e)}
                      onBlur={e =>
                        this.setState({ case_num_case_num: e.target.value })
                      }
                      onChange={e => this.checkError(e)}
                    />
                  </div>
                  <input
                    type="button"
                    title="Search"
                    value="Search"
                    id="case-search-submit"
                    className="btn-lg btn-primary search-button usa-button usa-search-big"
                    disabled={this.state.errorFieldIds.length > 0}
                    onClick={
                      this.state.errorFieldIds.length === 0
                        ? this.caseSearchSubmit
                        : null
                    }
                  />
                  <input
                    type="button"
                    title="Clear Search"
                    value="Clear Search"
                    id="case-search-clear"
                    className="btn-link"
                    onClick={this.handleClearSearch}
                  />
                </div>
              </form>
            </div>
          </div>
        )
    );
  }
}

Input.contextType = SearchContext;
export default Input;
