const case_type = {
  ACA: "Affordable Care Act",
  ACM: "Alien Crew Member",
  AIR: "Aviation Investment and Reform Act (AIR21)",
  ARN:
    "Immigration and Nursing Relief Act (INRA) - Nonimmigrant Nurses (H-1A and H-1C visas)",
  BCA: "Board of Contract Appeals",
  BCP: "Black Lung Benefits Act (Civil Penalty)",
  BLA: "Black Lung Benefits Act (Claims for Benefits)",
  BLB: "Black Lung Benefits Act (SSA Subpart B transfer)",
  BLO: "Black Lung Benefits Act (Overpayment)",
  BLP: "Black lung Benefits Act (Penalty)",
  BMI: "Black Lung Benefits Act ( Medical Interest)",
  BMO: "Black Lung Benefits Act (Medical Benefits Only)",
  BTD: "Black Lung Benefits Act (Medical Treatment Dispute)",
  CAA: "Clean Air Act",
  CBV: "'Collective Bargaining Variance (CBV)' under the Service Contract Act",
  CCP:
    "'Compensation Conformity Proceedings (CCP)' - Decertification proceedings under the Federal Unemployment Tax Act or the National Apprenticeship Act of 1937",
  CER:
    "Comprehensive Environmental Response, Compensation and Liability Act (CERCLA)",
  CET: "Comprehensive Employment and Training Act (CETA)",
  CFP: "Consumer Financial Protection Act (CFPA) of 2010 (Dodd-Frank, § 1057)",
  CLA: "Child Labor Provisions of the Fair Labor Standards Act",
  CPA: "Comprehensive Employment and Training Act (CETA) Program Audit",
  CPS: "Consumer Product Safety Improvement Act (CPSIA)",
  CRA: "Program Fraud Civil Remedies Act",
  CTA: "Comprehensive Employment and Training Act (CETA)",
  DBA: "Davis Bacon Act",
  DCA: "Debt Collection Act",
  DCW: "District of Columbia Workers' Compensation (DCWCPA)",
  EPP: "Employee Polygraph Protection Act (EPPA)",
  ERA: "Energy Reorganization Act",
  ESA: "Employment Standards Act",
  FDA: "FDA Food Safety Modernization Act (FSMA)",
  FLS: "Fair Labor Standards Act (FLSA)",
  FRS: "Federal Rail Safety Act (FRSA)",
  FUT: "FCD Unemployment Tax",
  HAV: "Longshore and Harbor Workers' Compensation Act (hand arm vibration)",
  INA: "Pre-PERM permanent alien labor certification",
  JSA: "Job Service Complaint System",
  JSW: "Job Service Complaint System Prevailing Wage LCA Appeal",
  JTP: "Job Training Partnership Act",
  LCA: "Labor Condition Applications (H-1B visas)",
  LDA: "Defense Base Act and Related Acts",
  LHC: "Longshore and Harbor Workers' Compensation Act (claims)",
  LHK: "Longshore and Harbor Workers' Compensation Act (penalties)",
  MAP: "Moving Ahead for Progress in the 21st Century Act",
  MBO: "Medical Benefits Only",
  MIS: "Miscellaneous Case Type",
  MSA:
    "Petition for modification of mine safety standard under the Federal Mine Safety & Health Act",
  MSP: "Migrant and Seasonal Occupational Worker Act",
  NHA: "United States Housing Act",
  NQW: "Nondisplacement of Qualified Workers",
  NTS: "National Transit Systems Security Act (NTSSA) of 2007",
  OAA: "Older Americans Act",
  OFC: "Office of Federal Contract Compliance Programs (OFCCP)",
  OSH: "OSHA Rulemaking",
  PCA: "Walsh-Healy Public Contracts Act",
  PED: "Alien labor certification debarment/revocation (H-2A, H-2B, PERM)",
  PER: "PERM permanent alien labor certification",
  PSI: "Pipeline Safety Improvement Act Whistleblower",
  PWD: "Prevailing Wage Determination (H-1B, H-2B, PERM)",
  RIS: "Employee Retirement Income Security Act (ERISA)",
  SAW: "Special Agriculture Worker",
  SCA: "Service Contract Act",
  SCC: "Service Contract Act (Worker Hours)",
  SDW: "Safe Drinking Water Act (SDWA)",
  SOC: "'Standards of Conduct' in federal labor unions under the LMRDA",
  SOX: "Sarbanes-Oxley Act",
  SPA: "Seaman's Protection Act",
  STA: "Surface Transportation Assistance Act (STAA)",
  SWD: "Solid Waste Disposal Act (SWDA)",
  TAE: "'Temporary alien employment' (H-2A visas - Wage & Hour enforcement)",
  TLC: "'Temporary labor certification' (H-2A cases - ETA certfications)",
  TLN: "'Temporary labor certification' (H-2B cases - ETA certfications)",
  TNE: "'Temporary labor certification' (H-2B cases - Wage & Hour enforcement)",
  TRA: "Trade Act",
  TSC: "Toxic Substances Control Act (TSCA)",
  TSE: "Temporary Student Employment",
  UIA: "Federal State Extended Unemployment Tax Act (FSEUTA)",
  VET: "Veteran Reemployment Training",
  WIA: "Workforce Innovation and Opportunity Act (WIOA)",
  WIN: "Work Incentive Program",
  WPA: "Wagner-Peyser Act (Job Service Complaint System)",
  WPC: "Federal Water Pollution Control Act (FWPCA)",
  WTW: "'Welfare to Work' cases under the Social Security Act"
};
const agency = {
  ARB: "Administrative Review Board",
  OALJ: "Office of Administrative Law Judges",
  "BRB Black Lung (unofficial database)": "Benefits Review Board",
  BRB: "Benefits Review Board",
  Courts: "Selected federal court decisions (not comprehensive)"
};

const program_area = {
  "Black Lung": `Black Lung Benefits Act (BLA, BLB, BLO, BMO, BTD)`,
  "Board of Contract Appeals": `Labor Board of Contract Appeals (now defunct) (BCA)`,
  "DBA, SCA and Related": `Davis-Bacon Act, Service Contract Act, and related laws (DBA, SCA)`,
  ERISA: `EBSA civil money penalty cases under ERISA regulations (RIS)`,
  Immigration: `Temp alien labor cert, WHD enforcement; prevailing wage; debarment; disqualification (JSA, JSW, LCA, PED, PWD, TAE, TLC, TLN, TNE)`,
  "Immigration PERM": `Permanent alien labor certification (BALCA) (PER and INA)`,
  "Immigration - PERM": `Permanent alien labor certification (BALCA) (PER and INA)`,
  Longshore: `Longshore & Harbor Workers' Comp Act and its extensions such as Defense Base Act  (LHC, DCW, LDA)`,
  OFCCP: `Office of Federal Contract Compliance Programs (OFC)`,
  "Other DOL": `Debt Col Act (DCA); Prog Fraud Civ Remedies (CRA); Mine Safety Stds Mod (MSA); Misc (MIS); Stds of Conduct - OLMS (SOC)`,
  "Other ETA": `Appren & Training EEO; Comp. Conf &  other decert (OAA); Trade Act (TRA); Unemploy. Ins. - FUTA (UIA); Welfare-to-Work (WTW)`,
  "Other WHD": `Fair Lab Stds Act (CLA, FLS); Coll Bar Var (CBV); Empl Poly Prot Act (EPP); Migrant & Sea Work Prot Act (MSP); Nondisp Qual Work (NQW)`,
  "Rules of Practice": `Rules of Practice and Procedure, 29 CFR Part 18`,
  Whistleblower: `(ACA, AIR, CAA, CERCLA, CFP, ERA, FDA, FRS, MAP, NTS, PSI, SDW, SOX, SPA, STA, SWD, TSC, WPC)`,
  "WIOA, WIA, JTPA, CETA": `Workforce Investment & Opportunity Act and its predecessors (WIA, JTP, CET)`
};

export { case_type, agency, program_area };
