import React, { Component } from 'react';
import CurrentActiveFilters from './currentActiveFilters';
import CurrentActiveDateFilters from './currentActiveDateFilters';

import { SearchContext } from '../../context/searchContext';

/*
CurrentFilters component checked which filters are currently checked and displays them on top of
the facet container.
*/
class CurrentFilters extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.context.clearAllFilters();
  }

  render() {
    let activeFilters = null;
    let activeDateFilters = null;

    // Check if there are any checked filters
    if(this.context.active_filters.length > 0) {
      activeFilters = <CurrentActiveFilters />
    }

    // If there is lower or upper dates, then assign CurrentActiveDateFilters to activeDateFilters
    if(this.context.date_filters.length > 0) {
      if (this.context.date_filters[0] !== null || this.context.date_filters[1] !== null) {
        activeDateFilters = <CurrentActiveDateFilters/>
      }
    }
    
    return (
      activeFilters !== null || activeDateFilters !== null ?
      <div>
        {/* Render all checked filters */}
      <h2>Current Filters</h2>
      { activeFilters }
      { activeDateFilters }
        <div 
        style={{ paddingTop: '10px' }}
        id="clear-filters-button"
        >
          <input 
          type="button"
          title="Clear All Filters"
          id="clear-filters"
          className="usa-button"
          onClick={this.handleClick}
          value="Clear All Filters" />
        </div>
      </div>
        : null
    );
  }
}

CurrentFilters.contextType = SearchContext;
export default CurrentFilters;
