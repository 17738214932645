import React, { Component } from 'react';
import { SearchContext } from './context/searchContext';
import SearchPage from './SearchPage';
import fetchAPI from "./fetchAPI";

class API extends Component {

  // Each time when a component updates, check if anything has been typed into search box. If nothing has been typed then do nothing, otherwise proceed to query fresh data
  componentDidUpdate() {
    let value = this.context;
    if (!value.query) {
      return null;
    }
    else {
      fetchAPI(value);
    }

  }

  render(){
    return (
      <div>
        <SearchPage />
      </div>
      );
  }
}


API.contextType = SearchContext;
export default API;