import React, { Component } from 'react';
import { SearchContext } from '../../context/searchContext';
import moment from 'moment';

/*
ActiveDateFilter renders a single checked date filter, and provides ability to clear it using a context method.
*/
class ActiveDateFilter extends Component {
    constructor(props) {
    super(props);
    
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.context.clearSingleDate(this.props.id, this.props.bound);
  }

  render() {
    const formattedTime = moment(this.props.name).format('MM-DD-YYYY');
    
    return (
      <div
      onClick={this.handleClick}
      >
        <span 
        className="cancel"
        >
          <svg 
          style={{ transform: 'translateY(-3px)' }}
          className="fa-times"
          viewBox="0 0 1792 1792"
          xmlns="http://www.w3.org/2000/svg"
          >
            <path 
            fill="#D3393B" 
            d="M1490 1322q0 40-28 68l-136 136q-28 28-68 28t-68-28l-294-294-294 294q-28 28-68 28t-68-28l-136-136q-28-28-28-68t28-68l294-294-294-294q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294 294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68l-294 294 294 294q28 28 28 68z"
            >
            </path>
          </svg>
          <span style={{ marginRight: '5px' }}>{this.props.bound.replace(/\s/g, '') === 'ge' ? "Start Date: " : 'End Date: '}</span>{formattedTime}
        </span>
      </div>
    );
  }
}

ActiveDateFilter.contextType = SearchContext;
export default ActiveDateFilter;
