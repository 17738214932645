import React, { Component } from 'react';
import SearchProvider from './context/searchContext';
import API from './Api';
import './responsive.css';
import './theme.css';

let ua = window.navigator.userAgent;
let msie = ua.indexOf("MSIE ");

if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./))  // If Internet Explorer, return version number
{
  require('@babel/polyfill');
}

class App extends Component {

  render() {
    return (
      <SearchProvider>
        <div id="keword-search-main">
          <API />
        </div>
      </SearchProvider>
    );
  }
}

export default App;
