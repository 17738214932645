import React, { Component } from 'react';
import { SearchContext } from '../../context/searchContext';
import './pager.css';

class Pager extends Component {

  handleSwitcher = (switchTo) => {
    //this handles the page switching for prev,next,first,last pagers so we can pass numbers directly.
    let pagerContext = this.context;
    window.scrollTo(0,0); //take us back to the top of the page
    pagerContext.switchPage(switchTo);
  };

  handleClick = (e) => {
    //this just takes the value off the pager input and switches to that page.
    let pagerContext = this.context;
    window.scrollTo(0,0); //take us back to the top of the page
    pagerContext.switchPage(parseInt(e.target.value));
  };

  findPages = (resultCount, numDisplay = 30) => {
    //get number of pages, we can pass the number to display out of the context at some point for display modifiers
    return Math.ceil(resultCount/numDisplay);
  };

  generateHtml = (numPages) => {
    let pagerContext = this.context;
    let pagerHtml = [];
    let currentPage = pagerContext.skip/pagerContext.top; //it's actually 1 less than the current page
    let renderedNum = 0; //iterator for tracking number of pages around current one to show + 4 (prev,next,first,last)
                        // used 11 here so we could do 5 and 5 around the current page.
    let maxRendered = (window.screen.width < 900 ? window.screen.width < 768 ? window.screen.width < 480 ? 5 : 7 : 9 : 11); //max number to render per desktop or mobile
    let maxRenderedOffset = maxRendered-1; //determine last page for styling
    let renderedMidPointCeil = (window.screen.width < 900 ? window.screen.width < 768 ? window.screen.width < 480 ? 3 : 4 : 5 : 6); //iterators vary on desktop or mobile
    let renderedMidPointFloor = (window.screen.width < 900 ? window.screen.width < 768 ? window.screen.width < 480 ? 2 : 3 : 4 : 5); //iterators vary on desktop of mobile
    if (currentPage) {
      //^ current page is offset by 1 so we don't show prev page pager on the first page
      pagerHtml.push(<div key={'back'} id={'pager_back'}>
        <input type={"button"} onClick={e => this.handleSwitcher(currentPage)} title={'Switch to previous page'}
               value={'<'}/>
      </div>);
      if ((currentPage - renderedMidPointCeil >= 0)) { //only show skip to first page if it's not naturally visible
        pagerHtml.push(<div key={'first'} id={'pager_first'} className={'pagerFirst'}>
          <input type={"button"} onClick={e => this.handleSwitcher(1)} title={'Switch to first page'}
                 value={1}/>
        </div>);
        pagerHtml.push(<div key={'continuation_back'}><input type={"button"} onClick={e => this.handleSwitcher((currentPage - 9) > 1 ? (currentPage-9) : 1)} key={'continuation_first'} className={'continuation'} title={"Skip back"} value={"..."}/></div>);
      }
    }
    if (currentPage > renderedMidPointFloor) {
      //this is so we get a consistent number of pagers printed even if they're on the first page
      //don't want to only get 5 on either side.
      for (let i = currentPage-renderedMidPointFloor; i < numPages; i++) {
        if (renderedNum === maxRendered) {
          //that's enough
          break;
        }
        if (i !== currentPage) {
          pagerHtml.push(<div key={i} id={'pager_' + (i + 1)}>
            <input type={"button"} onClick={e => this.handleClick(e)} title={'Switch to page ' + (i + 1)} value={i + 1} className={renderedNum === maxRenderedOffset ? 'pagerRight' : ''}/>
          </div>);
        }
        else { //current page gets special styling
          pagerHtml.push(<div key={i} id={'pager_' + (i + 1)}>
            <input type={"button"} className={'current ' + (renderedNum === maxRenderedOffset ? 'pagerRight' : '')} title={'current page'}
                   value={i + 1}/>
          </div>);
        }
        renderedNum++;
      }
    }
    else {
      for (let i = 0; i < numPages; i++) {
        if (renderedNum === maxRendered) {
          break;
        }
        if (i !== currentPage) {
          pagerHtml.push(<div key={i} id={'pager_' + (i + 1)}>
            <input type={"button"} onClick={e => this.handleClick(e)} title={'Switch to page ' + (i + 1)}
                   className={renderedNum === maxRenderedOffset ? 'pagerRight' : ''} value={i + 1}/>
          </div>);
        }
        else { //current page gets special styling.
          pagerHtml.push(<div key={i} id={'pager_' + (i + 1)}>
            <input type={"button"} className={'current ' + (renderedNum === maxRenderedOffset ? 'pagerRight' : '')} title={'Current Page'}
                   value={i + 1}/>
          </div>);
        }
        renderedNum++;
      }
    }
    if (numPages > 1) {//don't show switch to last or next if there's only 1 page or less
      if (!(currentPage + renderedMidPointCeil >= numPages)) { //only show skip to last page if last page won't show up naturally
        pagerHtml.push(<div key={'continuation_forward'}><input type={"button"} onClick={e => this.handleSwitcher((currentPage + 11) < numPages ? (currentPage+11) : numPages)} key={'continuation_last'} className={'continuation'} title={"Skip ahead"} value={"..."}/></div>);
        pagerHtml.push(<div key={'last'} id={'pager_last'}>
          <input type={"button"} onClick={e => this.handleSwitcher(numPages)} title={'Switch to last page'}
                 value={numPages}/>
        </div>);
      }
      if (currentPage +1 !== numPages) { //don't render next page on the last page
        pagerHtml.push(<div key={'next'} id={'pager_next'} className={'pagerNext'}>
          <input type={"button"} onClick={e => this.handleSwitcher(currentPage + 2)} title={'Switch to next page'}
                 value={'>'}/>
        </div>);
      }
    }
    return pagerHtml;
  };

  render(){
    let pagerContext = this.context;
    //let numPages = this.findPages(pagerContext.count, pagerContext.top);
    let numPages = this.findPages((pagerContext.count < 100000 ? pagerContext.count : 100000) , pagerContext.top);
    // let numPages =  (pagerContext.count < 100000 ? pagerContext.count : 100000) 
    if (pagerContext.results.length > 0) {
      return (
        <div id="pager">
          {this.generateHtml(numPages)}
        </div>
      );
    }
    else {
      return null;
    }
  }
}

Pager.contextType = SearchContext;
export default Pager;